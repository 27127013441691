import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ArchiveEntry } from "../_db/db";
import { getEntryFromHistoryFromIndex } from "../_db/repositoryHistory";
import { AsyncThunkCustomType } from "./store";
import { getEntryFromSavedFromIndex } from "../_db/repositorySaved";

export interface DrawerPaginationInfo {
  nrOfElementsOnDisplay: number;
  currentPage: number;
  nrOfPagesInScroll: number;
  prevScroll: number;
}

export interface DrawerSlice {
  env: DrawerPaginationInfo;
  historyData: ArchiveEntry[];
  savedData: ArchiveEntry[];
}
const initialState: DrawerSlice = {
  historyData: [],
  savedData: [],

  env: {
    nrOfElementsOnDisplay: 17,
    currentPage: 1,
    nrOfPagesInScroll: 5,
    prevScroll: 0,
  },
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: initialState,
  reducers: {
    setEnvCurrentPage: (state, action) => {
      state.env.currentPage = action.payload;
    },

    setHistory: (state, action) => {
      state.historyData = action.payload;
    },

    addEntriesToHistoryAtTheBeginning: (state, action) => {
      if (action.payload.length != 0) {
        state.historyData = state.historyData.slice(0, -action.payload.length);
        state.historyData.unshift(...action.payload);
      }
    },
    addEntriesToHistoryAtTheEnd: (state, action) => {
      state.historyData = state.historyData.slice(action.payload.length);
      state.historyData.push(...action.payload);
    },

    clearHD: (state) => {
      state.historyData = [];
    },

    setSaved: (state, action) => {
      state.savedData = action.payload;
    },

    addEntriesToSavedAtTheBeginning: (state, action) => {
      if (action.payload.length != 0) {
        state.savedData = state.savedData.slice(0, -action.payload.length);
        state.savedData.unshift(...action.payload);
      }
    },
    addEntriesToSavedAtTheEnd: (state, action) => {
      state.savedData = state.savedData.slice(action.payload.length);
      state.savedData.push(...action.payload);
    },

    clearSD: (state) => {
      state.savedData = [];
    },
  },
});

export const getMoreHistoData = createAsyncThunk<
  ArchiveEntry[],
  { numberOfElements: number; offset: number; next: boolean; replace: boolean },
  AsyncThunkCustomType
>(
  "drawer/getMoreHistoEntries",
  async ({ offset, numberOfElements, next, replace }, thunkApi) => {
    const list = await getEntryFromHistoryFromIndex(offset, numberOfElements);

    if (replace) {
      thunkApi.dispatch(setHistory(list));
    } else {
      if (next) {
        thunkApi.dispatch(addEntriesToHistoryAtTheEnd(list));
      } else {
        thunkApi.dispatch(addEntriesToHistoryAtTheBeginning(list));
      }
    }
    return list ?? [];
  }
);

export const getMoreSavedData = createAsyncThunk<
  ArchiveEntry[],
  { numberOfElements: number; offset: number; next: boolean; replace: boolean },
  AsyncThunkCustomType
>(
  "drawer/getMoreSavedEntries",
  async ({ offset, numberOfElements, next, replace }, thunkApi) => {
    const list = await getEntryFromSavedFromIndex(offset, numberOfElements);

    if (replace) {
      thunkApi.dispatch(setSaved(list));
    } else {
      if (next) {
        thunkApi.dispatch(addEntriesToSavedAtTheEnd(list));
      } else {
        thunkApi.dispatch(addEntriesToSavedAtTheBeginning(list));
      }
    }
    return list ?? [];
  }
);

export const {
  setEnvCurrentPage,
  setHistory,
  addEntriesToHistoryAtTheBeginning,
  addEntriesToHistoryAtTheEnd,
  clearHD,
  setSaved,
  addEntriesToSavedAtTheEnd,
  addEntriesToSavedAtTheBeginning,
  clearSD,
} = drawerSlice.actions;

export default drawerSlice.reducer;
