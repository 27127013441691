import {
  AppShell,
  Navbar,
  Tooltip,
  UnstyledButton,
  rem,
  Stack,
} from "@mantine/core";

import { CirclePlus, Menu2, Lock, Clock, Bookmark } from "tabler-icons-react";

import classes from "./NavBar.module.css";
import Main from "../Pages/Main";
import {
  ActiveSelectionType,
  DrawerType,
  PageType,
  RoutingInfoType,
  setActiveSelection,
  setDrawer,
  setPage,
} from "../data/_store/RoutingSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../data/_store/store";

type NavBarData = {
  label: ActiveSelectionType;
  icon: any;
  drawerName: DrawerType;
  pageName: PageType;
};

const navData: NavBarData[] = [
  {
    label: "New",
    icon: CirclePlus,
    drawerName: undefined,
    pageName: "new",
  },
  {
    label: "Collections",
    icon: Menu2,
    drawerName: "collections",
    pageName: undefined,
  },
  {
    label: "Environments",
    icon: Lock,
    drawerName: "environments",
    pageName: undefined,
  },
  {
    label: "Saved",
    icon: Bookmark,
    drawerName: "saved",
    pageName: undefined,
  },
  {
    label: "History",
    icon: Clock,
    drawerName: "history",
    pageName: undefined,
  },
];

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        onClick={onClick}
        className={classes.link}
        data-active={active || undefined}
      >
        <Icon style={{ width: rem(20), height: rem(20) }} />
      </UnstyledButton>
    </Tooltip>
  );
}

export default function NavBar(props: any) {
  const dispatch = useDispatch<any>();

  const routingInfo = useSelector<RootState, RoutingInfoType>(
    (state) => state.routingInfo
  );

  const links = navData.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={link.label === routingInfo.activeSelection}
      onClick={() => {
        dispatch(setActiveSelection(link.label));
        dispatch(setDrawer(link.drawerName));
        if (link.pageName) {
          dispatch(setPage(link.pageName));
        }
      }}
    />
  ));

  return (
    <AppShell
      padding="0px"
      navbar={
        <>
          <Navbar
            p="md"
            style={{border:0}}
            hiddenBreakpoint="sm"
            hidden={false}
            width={{ sm: 80, lg: 80 }}
            className={classes.navbar}
          >
            <div className={classes.navbarMain}>
              <Stack justify="center" className="stack">
                {links}
              </Stack>
            </div>
          </Navbar>
        </>
      }
    >
      <Main />
    </AppShell>
  );
}

interface NavbarLinkProps {
  icon: any;
  label: string;
  active?: boolean;
  onClick?(): void;
}
