import { Tabs } from "@mantine/core";
import classes from "../Sio.module.css";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { setHeaders, SioDetails } from "../../../data/_store/SioSlice";
import { RootState } from "../../../data/_store/store";
import HeadersTab from "../../Rest/TabsInput/HeadersTab";
import Chat from "./Chat/Chat";
import InputBox from "./InputBox/InputBox";
import Preferences from "./Preferences";

export default function Tab() {
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );

  const [activeTab, setActiveTab] = useState("body");
  useEffect(() => {
    if (sioDetails.connection === "disconnected") setActiveTab("body");
    if (sioDetails.connection === "connected") setActiveTab("body");
  }, [sioDetails.connection]);

  return (
    <Tabs className={classes.tabs} value={activeTab}>
      <Tabs.List>
        <Tabs.Tab
          value="header"
          disabled={sioDetails.connection === "connected"}
          onClick={() => setActiveTab("header")}
        >
          Header
        </Tabs.Tab>
        <Tabs.Tab value="body" onClick={() => setActiveTab("body")}>
          Details
        </Tabs.Tab>
        <Tabs.Tab
          value="preferences"
          disabled={sioDetails.connection !== "connected"}
          onClick={() => setActiveTab("preferences")}
        >
          Preferences
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="header" className={classes.tab}>
        <HeadersTab
          key={JSON.stringify(sioDetails.headers, null, 2)}
          saveToStore={setHeaders}
          initialValue={sioDetails.headers}
        />
      </Tabs.Panel>
      <Tabs.Panel value="body" className={classes.tab}>
        <PanelGroup direction="vertical">
          <Panel minSize={20} className={classes.firstPanel}>
            <Chat />
          </Panel>
          <PanelResizeHandle />
          <Panel minSize={20} className={classes.sndPanel}>
            <InputBox />
          </Panel>
        </PanelGroup>
      </Tabs.Panel>
      <Tabs.Panel value="preferences" className={classes.tab}>
        <Preferences />
      </Tabs.Panel>
    </Tabs>
  );
}
