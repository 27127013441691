import { EnvItemType } from "../_store/EnvSlice";
import { PageType } from "../_store/RoutingSlice";
import { EnvEntry, EnvEntryData, db } from "./db";

export const getEntryFromEnvById = async (id: string) => {
  try {
    const response = await db.env.get(id);
    return response;
  } catch (error) {
    console.log("Errr getEntryFromEnvById");
  }
};

export const getAllEntriesFromEnv = async () => {
  try {
    const response = await db.env.orderBy("date").reverse().toArray();
    return response;
  } catch (error) {
    console.log("Errr getAllEntriesFromEnv", error);
  }
};
export const getEntryFromEnvFromIndex = async (
  startIndex: number,
  nrOfItems: number
) => {
  try {
    const response = await db.env
      .orderBy("date")
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getEntryFromEnvFromIndex");
  }
};

export const getFilteredEntryFromEnvFromIndex = async (
  startIndex: number,
  nrOfItems: number,
  searchedParam: string
) => {
  try {
    const response = await db.env
      .orderBy("date")
      .filter((entry) => filterEntry(entry, searchedParam))
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getFilteredEntryFromEnvFromIndex");
  }
};

export const deleteEntryFromEnvById = async (id: string) => {
  try {
    const response = await db.env.delete(id);
    return response;
  } catch (error) {
    console.log("Errr deleteEntryFromEnvById");
  }
};

export const updateEntryFromEnvById = async (
  id: string,
  name: string,
  kvList: Record<string, EnvItemType>
) => {
  try {
    const response = await db.env.update(id, { data: { name, kvList } });
    return response;
  } catch (error) {
    console.log("Errr updateEntryFromEnvById");
  }
};

export const addEntryToEnv = async (type: PageType, data: EnvEntryData) => {
  try {
    const date = new Date();
    const id: string = crypto.randomUUID();
    await db.env.add({ id, type, data, date });
  } catch (error) {
    console.log("Errr addEntryToEnv", error);
  }
};

export const nrOfEntriesInEnv = async (): Promise<number> => {
  try {
    const size = await db.env.count();
    return size;
  } catch (error) {
    console.log("Errr nrOfEntriesInEnv");
  }
  return 0;
};
export const nrOfFilteredEntriesInEnv = async (
  searchedParam: string
): Promise<number> => {
  try {
    const size = await db.env
      .filter((entry) => filterEntry(entry, searchedParam))
      .count();
    return size;
  } catch (error) {
    console.log("Errr nrOfFilteredEntriesInEnv");
  }
  return 0;
};

const filterEntry = (entry: EnvEntry, searchedParam: string) => {
  return entry.data.name
    ? entry.data.name.toLowerCase().includes(searchedParam.toLowerCase())
    : false;
};
