import { ActionIcon, Text } from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TrashX } from "tabler-icons-react";
import { ArchiveEntry } from "../../data/_db/db";
import {
  deleteEntryFromHistoryById,
  nrOfEntriesInHistoryLQ,
} from "../../data/_db/repositoryHistory";
import { getMoreHistoData } from "../../data/_store/DrawerSlice";
import { loadRequest as loadHTTPInfo } from "../../data/_store/HttpMethodSlice";
import { setPage } from "../../data/_store/RoutingSlice";
import { loadSavedInfo as loadSioInfo } from "../../data/_store/SioSlice";
import { RootState } from "../../data/_store/store";
import { loadSavedInfo as loadWsInfo } from "../../data/_store/WebsocketSlice";
import { launchEvent } from "../../helpers/events";
import classes from "./Drawers.module.css";
import InfiniteScroll from "./InfiniteScroll";

const nrOfElementsOnDisplay = 50;
const batchNumber = 10;

const getHistoLabel = (histoEntry: ArchiveEntry): string => {
  switch (histoEntry.protocol) {
    case "rest":
      return `${histoEntry.data.httpMethod.toUpperCase()}  ${
        histoEntry.data.uri
      }`;

    case "websocket":
      return `WEBSOCKET ${histoEntry.data.uri} `;
    case "sio":
      return `SIO ${histoEntry.data.uri} `;
  }
  return "";
};

const loadRequest = (dispatch: any, histoEntry: ArchiveEntry) => {
  switch (histoEntry.protocol) {
    case "rest":
      dispatch(loadHTTPInfo(histoEntry.data));
      dispatch(setPage(histoEntry.protocol));
      break;
    case "websocket":
      dispatch(loadWsInfo(histoEntry.data));
      dispatch(setPage(histoEntry.protocol));
      break;
    case "sio":
      dispatch(loadSioInfo(histoEntry.data));
      dispatch(setPage(histoEntry.protocol));
      break;
  }
};
const builderFunction = (entry: ArchiveEntry, dispatch: any): any => {
  return (
    <div className={classes.inputGroup} key={`${entry.id}`} id={`${entry.id}`}>
      <Text
        className={`${classes.inputButton}`}
        onClick={() => {
          loadRequest(dispatch, entry);
        }}
      >
        {getHistoLabel(entry)}
      </Text>
      <ActionIcon
        color="red"
        className={classes.delete}
        onClick={() => {
          deleteEntryFromHistoryById(entry.id);
        }}
      >
        <TrashX />
      </ActionIcon>
    </div>
  );
};
export default function History() {
  const historyData = useSelector<RootState, ArchiveEntry[]>(
    (state) => state.drawerInfo.historyData
  );

  const nrOfElementsInDB: number = useLiveQuery(nrOfEntriesInHistoryLQ) ?? -1;
  const [prevNrOfElementsInDB, setPrevNrOfElementsInDB] = useState(-1);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (prevNrOfElementsInDB != -1) {
      launchEvent("onDbUpdate", {});
    }
    setPrevNrOfElementsInDB(nrOfElementsInDB);
  }, [nrOfElementsInDB]);

  const onBoundaryCall = (
    offset: number,
    numberOfElements: number,
    next: boolean,
    replace: boolean
  ) => {
    dispatch(
      getMoreHistoData({
        offset,
        numberOfElements,
        next,
        replace,
      })
    );
  };

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          overflow: "scroll",
          height: "85vh",
        }}
      >
        {nrOfElementsInDB > 0 && (
          <InfiniteScroll
            nrOfElementsOnDisplay={nrOfElementsOnDisplay}
            batchNumber={batchNumber}
            nrOfElementsInDB={nrOfElementsInDB}
            elementHeight={
              +getComputedStyle(document.body).getPropertyValue(
                "--height-input-group"
              )
            }
            onBoundaryCall={onBoundaryCall}
            data={historyData}
            builderFunction={(entry: ArchiveEntry) => {
              return builderFunction(entry, dispatch);
            }}
          />
        )}
      </div>
    </>
  );
}
