import { Group, Button, Input } from "@mantine/core";
import { Album } from "tabler-icons-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../data/_store/store";
import { CollectionDetails, updateName } from "../data/_store/CollectionSlice";
import { updateEntryFromCollectionsById } from "../data/_db/repositoryCollections";
import classes from "./Helpers.module.css";

export default function CollectionBar() {
  const dispatch = useDispatch<any>();

  const collectionDetails = useSelector<RootState, CollectionDetails>(
    (state) => state.collectionDetails
  );

  return (
    <>
      <Group className={classes.collectionDetails}>
        <Input
          style={{ marginTop: "10px" }}
          icon={<Album />}
          placeholder="Your collection name"
          className={classes.nameInput}
          value={collectionDetails.selectedCollection?.name}
          onChange={(event) => dispatch(updateName(event.target.value))}
        />
        <Button
          variant="gradient"
          gradient={{ from: "green", to: "green" }}
          className={classes.updateButton}
          onClick={() => {
            if (collectionDetails.selectedCollection !== null) {
              updateEntryFromCollectionsById(
                collectionDetails.selectedCollection
              );
            }
          }}
        >
          UPDATE
        </Button>
      </Group>
    </>
  );
}
