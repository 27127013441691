import { db, ConnectionData } from "./db";

export const nrOfEntriesInHistoryLQ = () => db.history.count();

export const getEntryFromHistoryById = async (id: number) => {
  try {
    const response = await db.history.get(id);
    return response;
  } catch (error) {
    console.log("Errr getEntryFromHistoryById");
  }
};

export const getAllEntriesFromHistory = async () => {
  try {
    const response = await db.history.orderBy("date").reverse().toArray();
    return response;
  } catch (error) {
    console.log("Errr getAllEntriesFromHistory", error);
  }
};
export const getEntryFromHistoryFromIndex = async (
  startIndex: number,
  nrOfItems: number
) => {
  try {
    const response = await db.history
      .orderBy("date")
      .reverse()
      .offset(startIndex)
      // .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getEntryFromHistoryFromIndex");
  }
};

export const deleteEntryFromHistoryById = async (id: string) => {
  try {
    const response = await db.history.delete(id);
    return response;
  } catch (error) {
    console.log("Errr deleteEntryFromHistoryById");
  }
};

export const addEntryToHistory = async (histoEntryData: ConnectionData) => {
  try {
    const date = new Date();
    const id: string = crypto.randomUUID();
    await db.history.add({ id, ...histoEntryData, date });
  } catch (error) {
    console.log("Errr addEntryToHistory:",
      histoEntryData.protocol,
      histoEntryData.data
    );
  }
};

export const nrOfEntriesInHistory = async (): Promise<number> => {
  try {
    const size = await db.history.count();
    return size;
  } catch (error) {
    console.log("Errr nrOfEntriesInHistory");
  }
  return 0;
};

