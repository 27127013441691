import { Input, Tabs, Textarea, Text } from "@mantine/core";
import classes from "../Rest.module.css";
import { useSelector } from "react-redux";
import { HttpDetails } from "../../../data/_store/HttpMethodSlice";
import { RootState } from "../../../data/_store/store";

export default function Rest() {
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );

  const tabsOutput = (
    <Tabs keepMounted={false} defaultValue="body" className={classes.tabs}>
      <Tabs.List>
        <Tabs.Tab value="header">Header</Tabs.Tab>
        <Tabs.Tab value="body">Body</Tabs.Tab>
        <Text className={classes.httpResponseCode}>
          {httpDetails.responseCode}
        </Text>
      </Tabs.List>

      <Tabs.Panel value="header" className={classes.tab}>
        {
          <div className={classes.tabContentWrapper}>
            <div className={classes.scrollableContent}>
              {httpDetails.responseHeaders &&
                Object.entries(httpDetails.responseHeaders).map(
                  ([key, value]) => {
                    return (
                      <div className={classes.inputGroup} key={key + value}>
                        <Input
                          className={classes.inputArea}
                          value={key ? key.toString() : ""}
                          readOnly
                        ></Input>
                        <Input
                          className={classes.inputArea}
                          value={value ? value.toString() : ""}
                          readOnly
                        ></Input>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        }
      </Tabs.Panel>
      <Tabs.Panel value="body" className={classes.tab}>
        {httpDetails.responseBody && (
          <Textarea
            autosize
            minRows={35}
            maxRows={35}
            value={httpDetails.responseBody}
            readOnly
          ></Textarea>
        )}
      </Tabs.Panel>
    </Tabs>
  );

  return tabsOutput;
}
