import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import connectToSIO from "../../Pages/SocketIO/SioManager";
import { EnvEntry, Protocol } from "../_db/db";
import { sioArray } from "../connections";
import { AsyncThunkCustomType } from "./store";
import { replaceRequestWithEnvVar } from "./Helpers";
export type MessageType = "sent" | "received" | "event";

export interface SioDetails {
  uri: string;
  crtEmittingChannel: string;
  emittingChannels: string[];
  receivingChannels: Record<string, boolean>;
  crtMessage: string;
  messages: MessageDataType[];
  sioId: string | null;
  headers: Record<string, string>;
  connection: ConnectionType;
  connectionErrMsg?: string | null | unknown;
  env: EnvEntry | null;
}
export type ConnectionType = "loading" | "connected" | "disconnected";
export type SioRequestType = {
  headers: Record<string, string>;
  uri: string;
};

export type MessageDataType = {
  message: string;
  type: MessageType;
  channel: string;
};

const initialState: SioDetails = {
  uri: "",
  crtEmittingChannel: "",
  emittingChannels: [],
  receivingChannels: {},
  crtMessage: "",
  messages: [],
  sioId: null,
  headers: {},
  connection: "disconnected",
  env: null,
};

export const sioSlice = createSlice({
  name: "sioDetails",
  initialState: initialState,
  reducers: {
    setUri: (state, action) => {
      state.uri = action.payload;
    },
    setEnv: (state, action) => {
      state.env = action.payload;
    },
    setCrtEmittingChannel: (state, action) => {
      state.crtEmittingChannel = action.payload;
    },

    updateEmittingChannels: (state, action) => {
      state.emittingChannels = action.payload;
    },
    addEmittingChannels: (state, action) => {
      state.emittingChannels.push(action.payload);
    },
    removeEmittingChannels: (state, action) => {
      state.emittingChannels.filter((channel) => action.payload !== channel);
    },

    addReceivingChannel: (state, action) => {
      state.receivingChannels = {
        ...state.receivingChannels,
        [action.payload]: true,
      };
    },

    updateReceivingChannel: (state, action) => {
      state.receivingChannels[action.payload.channel] = action.payload.enabled;
    },

    removeReceivingChannel: (state, action) => {
      const receivingChannels = {
        ...state.receivingChannels,
      };

      delete receivingChannels[action.payload];

      state.receivingChannels = receivingChannels;
    },

    setCrtMessage: (state, action) => {
      state.crtMessage = action.payload;
    },

    setMessages: (state, action) => {
      state.messages.push(action.payload);
    },

    removeNthMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message, idx) => idx !== action.payload
      );
    },

    setHeaders: (state, action) => {
      state.headers = action.payload;
    },

    disconnectSocketIo: (state, action) => {
      if (action.payload) {
        const message = action.payload;
        state.messages.push({ message, channel: "", type: "event" });
      }

      //Remove id from object
      const index: number = sioArray.findIndex(
        ({ sioId, functions }) => sioId === state.sioId
      );
      if (index > -1) {
        sioArray.splice(index, 1);
      }

      state.crtMessage = initialState.crtMessage;
      state.sioId = initialState.sioId;
      state.headers = initialState.headers;
      state.connection = initialState.connection;
      state.connectionErrMsg = initialState.connectionErrMsg;
      state.emittingChannels = initialState.emittingChannels;
      state.receivingChannels = initialState.receivingChannels;
      state.crtEmittingChannel = initialState.crtEmittingChannel;
      state.env = initialState.env;
    },
    loadSavedInfo: (state, action) => {
      state.uri = action.payload.uri;
      state.crtMessage = action.payload.crtMessage;
      state.messages = action.payload.messages;
      state.sioId = action.payload.sioId;
      state.headers = action.payload.headers;
      state.connection = action.payload.connection;
      state.crtEmittingChannel = action.payload.crtEmittingChannel;
      state.receivingChannels = action.payload.receivingChannels;
      state.emittingChannels = action.payload.emittingChannels;
      state.env = action.payload.env;
    },
    cleanErrMessage: (state) => {
      state.connectionErrMsg = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectSio.pending, (state, action) => {
        state.connection = "loading";
      })
      .addCase(connectSio.fulfilled, (state, action) => {
        state.crtMessage = initialState.crtMessage;
        const connectedMessage: MessageDataType = {
          message: "Connected",
          channel: "client",
          type: "event",
        };

        state.messages = [connectedMessage];
        state.sioId =
          typeof action.payload === "string" ? action.payload : null;
        state.connection = "connected";
      })
      .addCase(connectSio.rejected, (state, action) => {
        state.sioId = null;
        state.connection = "disconnected";
        state.connectionErrMsg = action.error.message;
      });
  },
});

export const connectSio = createAsyncThunk<
  string,
  SioRequestType,
  AsyncThunkCustomType
>("sioDetails/connectSio", async (sioRequest: SioRequestType, thunkApi) => {
  const dispatchReceivingChannel = (channel: String) => {
    const state: SioDetails = thunkApi.getState().sioDetails;

    if (state.receivingChannels[channel.toString()] == null)
      thunkApi.dispatch(addReceivingChannel(channel));
  };

  const dispatchMessage = (
    message: string,
    type: MessageType,
    channel: string
  ) => {
    const state: SioDetails = thunkApi.getState().sioDetails;

    if (
      (type === "sent" && state.crtEmittingChannel === channel) ||
      (type === "received" && state.receivingChannels[channel]) ||
      type === "event"
    ) {
      thunkApi.dispatch(setMessages({ message, type, channel }));
    }
  };

  const disconnect = (message: string) => {
    thunkApi.dispatch(disconnectSocketIo(message));
  };

  const env = thunkApi.getState().sioDetails.env;

  const { uri, headers } =
    env !== null
      ? replaceRequestWithEnvVar(
          {
            uri: sioRequest.uri,
            headers: sioRequest.headers,
            type: Protocol.SIO,
          },
          env
        )
      : { uri: sioRequest.uri, headers: sioRequest.headers };

  const resp = await connectToSIO(
    dispatchMessage,
    dispatchReceivingChannel,
    disconnect,
    uri,
    headers
  );
  return resp;
});

export const {
  setEnv,
  setUri,
  setCrtMessage,
  setMessages,
  loadSavedInfo,
  setHeaders,
  disconnectSocketIo,
  cleanErrMessage,
  addEmittingChannels,
  removeEmittingChannels,
  updateEmittingChannels,
  setCrtEmittingChannel,
  addReceivingChannel,
  updateReceivingChannel,
  removeReceivingChannel,
  removeNthMessage,
} = sioSlice.actions;

export default sioSlice.reducer;
