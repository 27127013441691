import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { EnvEntry, Protocol } from "../_db/db";
import { AsyncThunkCustomType } from "./store";
import { replaceRequestWithEnvVar } from "./Helpers";

export type AxiosRequestData = {
  method: string;
  url: string;
  headers: Record<string, string>;
  data: string;
};
export type QueryParam = {
  key: string;
  value: string;
};

export interface HttpDetails {
  httpMethod: string;
  uri: string;
  headers: Record<string, string>;
  queryParams: QueryParam[];
  pathParams: Record<string, string>;
  requestBody: string;
  responseHeaders: Record<string, string> | {}; // TODO: remove "| {}" part and fix types
  responseBody: string;
  responseState: string;
  responseCode: string;
  env: EnvEntry | null;
}
export interface HttpResponseDetails {
  responseHeaders: Record<string, string> | {}; // TODO: remove "| {}" part and fix types
  responseBody: string;
  responseState: string;
  responseCode: string;
}

const initialState: HttpDetails = {
  httpMethod: "get",
  uri: "",
  headers: {},
  queryParams: [],
  pathParams: {},
  requestBody: "",
  responseHeaders: {},
  responseBody: "",
  responseState: "unknown",
  responseCode: "",
  env: null,
};
export const httpDetailsSlice = createSlice({
  name: "httpDetails",
  initialState: initialState,
  reducers: {
    restoreToInitialValue: (state) => {
      state.httpMethod = initialState.httpMethod;
      state.uri = initialState.uri;
      state.headers = initialState.headers;
      state.queryParams = initialState.queryParams;
      state.pathParams = initialState.pathParams;
      state.requestBody = initialState.requestBody;
      state.responseHeaders = initialState.responseHeaders;
      state.responseBody = initialState.responseBody;
      state.responseState = initialState.responseState;
      state.responseCode = initialState.responseCode;
      state.env = initialState.env;
    },
    setEnv: (state, action) => {
      state.env = action.payload;
    },
    setHttpMethod: (state, action) => {
      state.httpMethod = action.payload;
    },
    setUri: (state, action) => {
      state.uri = action.payload;
    },
    setHeaders: (state, action) => {
      state.headers = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setPathParams: (state, action) => {
      state.pathParams = action.payload;
    },
    setRequestBody: (state, action) => {
      state.requestBody = action.payload;
    },
    setResponseCode: (state, action) => {
      state.responseCode = action.payload;
    },
    cleanResp: (state) => {
      state.responseHeaders = {};
      state.responseBody = "";
      state.responseState = "unknown";
      state.responseCode = "";
    },
    loadRequest: (state, action) => {
      state.headers = action.payload.headers;
      state.uri = action.payload.uri;
      state.httpMethod = action.payload.httpMethod;
      state.pathParams = action.payload.pathParams;
      state.queryParams = action.payload.queryParams;
      state.requestBody = action.payload.requestBody;
      state.responseHeaders = {};
      state.responseBody = "";
      state.responseState = "unknown";
      state.responseCode = "";
      state.env = action.payload.env;
    },
    loadSavedInfo: (state, action) => {
      state.httpMethod = action.payload.httpMethod;
      state.uri = action.payload.uri;
      state.headers = action.payload.headers;
      state.queryParams = action.payload.queryParams;
      state.pathParams = action.payload.pathParams;
      state.requestBody = action.payload.requestBody;
      state.responseHeaders = action.payload.responseHeaders;
      state.responseBody = action.payload.responseBody;
      state.responseState = action.payload.responseState;
      state.responseCode = action.payload.responseCode;
      state.env = action.payload.env;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchResponse.pending, (state, action) => {
        state.responseState = "loading";
      })
      .addCase(fetchResponse.fulfilled, (state, action) => {
        const responseBody = action.payload.data;
        if (typeof responseBody === "string") {
          state.responseBody = responseBody;
        } else {
          state.responseBody = JSON.stringify(responseBody, null, 2);
        }

        state.responseHeaders = action.payload.headers;
        state.responseState = "fulfilled";
        state.responseCode = "" + action.payload.status;
      })
      .addCase(fetchResponse.rejected, (state, action) => {
        state.responseState = "failed";
      });
  },
});

export const fetchResponse = createAsyncThunk<
  AxiosResponse<any, any>,
  AxiosRequestData,
  AsyncThunkCustomType
>("httpDetails/fetchResponse", async (request, thunkApi) => {
  const env: EnvEntry | null = thunkApi.getState().httpDetails.env;

  const requestUpdated = env
    ? replaceRequestWithEnvVar(
        {
          headers: request.headers,
          uri: request.url,
          method: request.method,
          data: request.data,
          type: Protocol.HTTP,
        },
        env
      )
    : request;

  const asyncResp = await axios({
    ...requestUpdated,
    validateStatus: () => true,
  });

  return asyncResp;
});

export const {
  setEnv,
  setHttpMethod,
  setUri,
  setHeaders,
  setQueryParams,
  setRequestBody,
  cleanResp,
  setResponseCode,
  setPathParams,
  loadRequest,
  loadSavedInfo,
  restoreToInitialValue,
} = httpDetailsSlice.actions;

export default httpDetailsSlice.reducer;
