function subscribeToEvent(eventName: string, listener: any) {
  document.addEventListener(eventName, listener);
}

function unsubscribeToEvent(eventName: string, listener: any) {
  document.removeEventListener(eventName, listener);
}

function launchEvent(eventName: string, data:any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { launchEvent, subscribeToEvent, unsubscribeToEvent };
