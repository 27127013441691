import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import connectToWebsocket from "../../Pages/Websocket/WsManager";
import { EnvEntry, Protocol } from "../_db/db";
import { wsArray } from "../connections";
import { AsyncThunkCustomType } from "./store";
import { replaceRequestWithEnvVar } from "./Helpers";
export type MessageType = "sent" | "received" | "event";

export type WsMessageType = { message: string; type: MessageType };

export interface WebsocketDetails {
  uri: string;
  crtMessage: string;
  messages: WsMessageType[];
  wsId: string | null;
  headers: Record<string, string>;
  connection: ConnectionType;
  connectionErrMsg?: string | null | unknown;
  env: EnvEntry | null;
}
export type ConnectionType = "loading" | "connected" | "disconnected";
export type WsRequestType = {
  dispatchData: any;
  uri: string;
};

const initialState: WebsocketDetails = {
  uri: "",
  crtMessage: "",
  messages: [],
  wsId: null,
  headers: {},
  connection: "disconnected",
  env: null,
};

export const websocketSlice = createSlice({
  name: "wsDetails",
  initialState: initialState,
  reducers: {
    restoreToInitialValue: (state) => {
      state.crtMessage = initialState.crtMessage;
      state.messages = initialState.messages;
      state.wsId = initialState.wsId;
      state.uri = initialState.uri;
      state.headers = initialState.headers;
      state.connection = initialState.connection;
      state.connectionErrMsg = undefined;
      state.env = initialState.env;
    },

    setUri: (state, action) => {
      state.uri = action.payload;
    },

    setEnv: (state, action) => {
      state.env = action.payload;
    },

    setCrtMessage: (state, action) => {
      state.crtMessage = action.payload;
    },
    setMessages: (state, action) => {
      state.messages.push(action.payload);
    },

    setHeaders: (state, action) => {
      state.headers = action.payload;
    },
    cleanUpAfterDisconnection: (state) => {
      //Remove id from object
      const index: number = wsArray.findIndex(
        ({ wsId, functions }) => wsId === state.wsId
      );
      if (index > -1) {
        wsArray.splice(index, 1);
      }

      state.crtMessage = initialState.crtMessage;
      // state.messages = initialState.messages;
      state.wsId = initialState.wsId;
      // state.uri = initialState.uri;
      state.headers = initialState.headers;
      state.connection = initialState.connection;
      state.connectionErrMsg = undefined;
      state.env = initialState.env;
    },
    disconnectWS: (state) => {
      //Call ws close
      if (state.wsId) {
        wsArray
          .find(({ wsId, functions }) => wsId === state.wsId)
          ?.functions.closeWs(state.wsId);
      }
      cleanUpAfterDisconnection();
    },
    loadSavedInfo: (state, action) => {
      state.uri = action.payload.uri;
      state.crtMessage = action.payload.crtMessage;
      state.messages = action.payload.messages;
      state.wsId = action.payload.wsId;
      state.headers = action.payload.headers;
      state.connection = action.payload.connection;
      state.env = action.payload.env;
    },
    cleanErrMessage: (state) => {
      state.connectionErrMsg = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectWs.pending, (state, action) => {
        state.connection = "loading";
      })
      .addCase(connectWs.fulfilled, (state, action) => {
        state.connection = "connected";

        state.crtMessage = initialState.crtMessage;
        const connectedMessage: WsMessageType = {
          message: "connected",
          type: "event",
        };
        state.messages = [connectedMessage];
        state.wsId = typeof action.payload === "string" ? action.payload : null;
      })
      .addCase(connectWs.rejected, (state, action) => {
        state.wsId = null;
        state.connection = "disconnected";
        state.connectionErrMsg = action.error.message;
      });
  },
});

export const connectWs = createAsyncThunk<string, void, AsyncThunkCustomType>(
  "wsDetails/connectWs",
  async (_, thunkApi) => {
    const dispatchData = (message: string, type: MessageType) => {
      const state: WebsocketDetails = thunkApi.getState().wsDetails;
      if (
        type === "received" ||
        type === "sent" ||
        (type === "event" &&
          message === "disconnected" &&
          state.connection === "connected")
      ) {
        thunkApi.dispatch(setMessages({ message, type }));
      }

      if (
        message === "disconnected" &&
        type === "event" &&
        state.connection === "connected"
      ) {
        thunkApi.dispatch(cleanUpAfterDisconnection());
      }
    };

    const env = thunkApi.getState().wsDetails.env;
    const uriToBeReplaced = thunkApi.getState().wsDetails.uri;
    const headersToBeReplaced = thunkApi.getState().wsDetails.headers;

    const { uri, headers } =
      env != null
        ? replaceRequestWithEnvVar(
            {
              uri: uriToBeReplaced,
              headers: headersToBeReplaced,
              type: Protocol.WS,
            },
            env
          )
        : { uri: uriToBeReplaced, headers: headersToBeReplaced };

    const resp = await connectToWebsocket(dispatchData, uri);
    return resp;
  }
);

export const {
  restoreToInitialValue,
  setEnv,
  setUri,
  setCrtMessage,
  setMessages,
  loadSavedInfo,
  setHeaders,
  disconnectWS,
  cleanErrMessage,
  cleanUpAfterDisconnection,
} = websocketSlice.actions;

export default websocketSlice.reducer;
