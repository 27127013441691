import Rest from "./Rest/Rest";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RoutingInfoType } from "../data/_store/RoutingSlice";
import { RootState } from "../data/_store/store";
import Collections from "../NavBar/Drawers/Collections";
import Drawer from "../NavBar/Drawers/Drawer";
import Env from "../NavBar/Drawers/env/Env";
import History from "../NavBar/Drawers/History";
import Saved from "../NavBar/Drawers/Saved";
import PageEnv from "../Pages/Env/PageEnv";
import classes from "./Main.module.css";
import New from "./New/New";
import Sio from "./SocketIO/Sio";
import Websocket from "./Websocket/Websocket";

export default function Main() {
  const routingInfo = useSelector<RootState, RoutingInfoType>(
    (state) => state.routingInfo
  );

  const drawer = useMemo(() => {
    switch (routingInfo.drawer) {
      case undefined:
        return null;
      case "history":
        return <History />;
      case "environments":
        return <Env />;
      case "collections":
        return <Collections />;
      case "saved":
        return <Saved />;
    }
  }, [routingInfo.drawer]);

  const page = useMemo(() => {
    switch (routingInfo.page) {
      case "new":
        return <New />;
      case "rest":
        return <Rest />;
      case "websocket":
        return <Websocket />;
      case "sio":
        return <Sio />;
      case "env":
        return <PageEnv />;
    }
    return null;
  }, [routingInfo.page]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.drawer}>
          {drawer && (
            <Drawer
              name={!routingInfo.drawer ? "" : routingInfo.drawer.toUpperCase()}
            >
              {drawer}
            </Drawer>
          )}
        </div>
        <div className={classes.menu}>
          <div className={classes.drawer}>{page}</div>
        </div>
      </div>
    </>
  );
}
