import {  Input, ActionIcon, TextInput } from "@mantine/core";
import { debounce } from "lodash";
import classes from "../Rest.module.css";
import { useEffect, useState } from "react";
import { TrashX } from "tabler-icons-react";

export type ParamsType = {
  delete: () => void;
  onPersistKey: (key: string) => void;
  onPersistValue: (value: string) => void;
  duplicateKey: string;
  kvKey: string;
  kvValue: string;
};
export default function KeyValueInput(params: ParamsType) {
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  const onDebouncedInputValueChange = debounce((value: string) => {
    setValue(value);
    params.onPersistValue(value);
  }, 300);

  const onDebouncedInputKeyChange = debounce((value: string) => {
    setKey(value);
    params.onPersistKey(value);
  }, 300);


  useEffect(() => {
    setKey(params.kvKey)
  }, [params.kvKey]);

  useEffect(() => {
    setValue(params.kvValue)
  }, [params.kvValue]);


  const getPairElements = (
    <div className={classes.inputGroup}>
      <TextInput
        className={classes.inputArea}
        defaultValue={key}
        error={params.duplicateKey}
        onChange={(event) =>
          onDebouncedInputKeyChange(event.currentTarget.value)
        }
      ></TextInput>
      <Input
        className={classes.inputArea}
        defaultValue={value}
        onChange={(event) =>
          onDebouncedInputValueChange(event.currentTarget.value)
        }
      ></Input>
      <ActionIcon
        color="red"
        className={classes.delete}
        onClick={() => {
          params.delete();
        }}
      >
        <TrashX />
      </ActionIcon>
    </div>
  );

  return getPairElements;
}
