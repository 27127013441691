import { ActionIcon, Button, Checkbox, Input } from "@mantine/core";
import classes from "../Sio.module.css";
import {
  addReceivingChannel,
  removeReceivingChannel,
  updateReceivingChannel,
} from "../../../data/_store/SioSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../data/_store/store";
import { SioDetails } from "../../../data/_store/SioSlice";
import { PhoneIncoming, TrashX } from "tabler-icons-react";
import { useState } from "react";

export default function Preferences() {
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );

  const dispatch = useDispatch<any>();
  const [crtReceivingChannel, setCrtReceivingChannel] = useState("");
  return (
      <div className={classes.receivingChannels}>
        <div className={classes.messageChannel}>
          <PhoneIncoming className={classes.chatIcon} />
          Receiving channels
        </div>

        <div className={classes.roomInputArea}>
          <Input
            className={classes.roomInputText}
            value={crtReceivingChannel}
            onChange={(event) =>
              setCrtReceivingChannel(event.currentTarget.value)
            }
          ></Input>

          <Button
            className={classes.addRoomButton}
            onClick={() => {
              dispatch(addReceivingChannel(crtReceivingChannel));
            }}
          >
            + Add channel
          </Button>
        </div>

        <div className={classes.preferencesList}>
          {Object.entries(sioDetails.receivingChannels).map(
            ([channel, enabled]) => {
              return (
                <div key={channel} className={classes.roomEntry}>
                  <Checkbox
                    className={classes.channelCheckbox}
                    size="xs"
                    label={channel}
                    checked={enabled}
                    key={channel}
                    // styles={{ label: { color: '#f15aac' } }}
                    onChange={(event: any) => {
                      const checked: boolean =
                        event.currentTarget.checked ?? false;
                      if (checked !== enabled)
                        dispatch(
                          updateReceivingChannel({ channel, enabled: checked })
                        );
                    }}
                  />
                  <ActionIcon
                    color="red"
                    className={classes.deleteCheckbox}
                    onClick={() => {
                      dispatch(removeReceivingChannel(channel));
                    }}
                  >
                    <TrashX />
                  </ActionIcon>
                </div>
              );
            }
          )}
        </div>
      </div>
  );
}
