import classes from "../Rest.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PathParam from "./PathParams";
import { RootState } from "../../../data/_store/store";
import {
  HttpDetails,
  setPathParams as storePathParams,
} from "../../../data/_store/HttpMethodSlice";

export default function PathParamsTab() {
  const [pathParams, setPathParams] = useState<Record<string, string>>({});
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const parseStringForPathParams = (urlWithPath: string) => {
      let pattern = /\{[a-zA-Z0-9]*\}/g;
      const brackets = /\{|\}/g;

      const pathParam = Array.from(urlWithPath.matchAll(pattern)).reduce(
        (acc, el) => {
          const key: string = el[0].replaceAll(brackets, "");
          const value = pathParams[key] ?? "";
          return { ...acc, [key]: value };
        },
        {}
      );

      setPathParams(pathParam);
    };
    parseStringForPathParams(httpDetails.uri);
  }, [httpDetails.uri]);

  useEffect(() => {
    dispatch(storePathParams(pathParams));
  }, [pathParams, dispatch]);

  const updateInput = (key: string) => (value: string) => {
    let prevPathParams = { ...pathParams };

    prevPathParams[key] = value;

    setPathParams(prevPathParams);
  };
  const getPathParams = () => {
    return Object.entries(pathParams)
      .sort((e1, e2) => parseInt(e1[0]) - parseInt(e2[0]))
      .map(([key, value]) => (
        <PathParam
          key={key + "KeyValueInput"}
          paramName={key}
          onPersistValue={updateInput(key)}
        />
      ));
  };

  return (
    <div className={classes.tabContentWrapper}>
      <div className={classes.scrollableContent}>{getPathParams()}</div>
    </div>
  );
}
