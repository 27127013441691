import { Button } from "@mantine/core";
import classes from "../Rest.module.css";
import { useEffect, useState } from "react";
import KeyValueInput from "./KeyValueInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../data/_store/store";
import {
  HttpDetails,
  QueryParam,
  setQueryParams,
} from "../../../data/_store/HttpMethodSlice";

export default function QueryParamsTab() {
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );

  const [queryParamList, setQueryParamList] = useState<QueryParam[]>([]);
  const [queryParamErrorList, setQueryParamErrorList] = useState<
    Record<number, string>
  >({});

  const dispatch = useDispatch();

  useEffect(() => {
    const errEntries: Record<number, string> = {};

    queryParamList.forEach((queryParam, index) => {
      if (countDuplicateKey(queryParam.key) > 1) {
        errEntries[index] = "Duplicate: Last value will be used";
      }
    });

    if (
      JSON.stringify(queryParamList) !== JSON.stringify(httpDetails.queryParams)
    ) {
      dispatch(setQueryParams(queryParamList));
    }
    setQueryParamErrorList(errEntries);
  }, [queryParamList]);

  const countDuplicateKey = (key: string) => {
    return Object.entries(queryParamList).filter(
      ([_uid, header]) => header.key.toLowerCase() === key.toLowerCase()
    ).length;
  };

  useEffect(() => {
    setQueryParamList(httpDetails.queryParams);
  }, [httpDetails.queryParams]);

  const add = (
    <Button
      variant="gradient"
      gradient={{ from: "orange", to: "red" }}
      className={classes.addButton}
      onClick={() => {
        createInput();
      }}
    >
      ADD
    </Button>
  );

  const createInput = () => {
    setQueryParamList((prevState) => [
      ...prevState,
      {
        key: "",
        value: "",
      },
    ]);
  };

  const updateInput =
    (type: "key" | "value", index: number) => (value: string) => {
      setQueryParamList((prevState) => [
        ...prevState.slice(0, index),
        { ...prevState[index], [type]: value },
        ...prevState.slice(index + 1),
      ]);
    };

  const deleteQueryParamList = (index: number) => () => {

    setQueryParamList((prevState) => {
      return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
    });
  };

  return (
    <div className={classes.tabContentWrapper}>
      {add}
      <div className={classes.scrollableContent}>
        {queryParamList.map((queryParam, index) => {
          return (
            <KeyValueInput
              kvKey={queryParam.key}
              kvValue={queryParam.value}
              key={queryParam.key+queryParam.value+index}
              delete={deleteQueryParamList(index)}
              onPersistKey={updateInput("key", index)}
              onPersistValue={updateInput("value", index)}
              duplicateKey={queryParamErrorList[index]}
            />
          );
        })}
      </div>
    </div>
  );
}
