import { Tabs, Textarea } from "@mantine/core";
import classes from "../Rest.module.css";

import { useSelector, useDispatch } from "react-redux";
import {
  HttpDetails,
  setRequestBody,
  setHeaders,
} from "../../../data/_store/HttpMethodSlice";
import { RootState } from "../../../data/_store/store";
import HeadersTab from "./HeadersTab";
import PathParamsTab from "./PathParamsTab";
import QueryParamsTab from "./QueryParamsTab";

export type KeyValueInputType = {
  key: string;
  value: string;
  updateName: (value: string) => void;
  updateValue: (value: string) => void;
};

export default function TabsInput() {
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );
  const dispatch = useDispatch();

  const textEditor = (
    <Textarea
      autosize
      minRows={35}
      maxRows={35}
      className={classes.requestBodyTextArea}
      value={httpDetails.requestBody}
      onChange={(event) => {
        dispatch(setRequestBody(event.currentTarget.value));
      }}
    ></Textarea>
  );

  return (
    <Tabs defaultValue="queryParams" className={classes.tabs}>
      <Tabs.List>
        <Tabs.Tab value="header">Header</Tabs.Tab>
        <Tabs.Tab value="body">Body</Tabs.Tab>
        <Tabs.Tab value="queryParams">Query</Tabs.Tab>
        <Tabs.Tab value="pathParams">Path Params</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="header" className={classes.tab}>
        <HeadersTab
          key={JSON.stringify(httpDetails.headers, null, 2)}
          saveToStore={setHeaders}
          initialValue={httpDetails.headers}
        />
      </Tabs.Panel>
      <Tabs.Panel value="body" className={classes.tab}>
        {textEditor}
      </Tabs.Panel>
      <Tabs.Panel value="queryParams" className={classes.tab}>
        <QueryParamsTab />
      </Tabs.Panel>
      <Tabs.Panel value="pathParams" className={classes.tab}>
        <PathParamsTab />
      </Tabs.Panel>
    </Tabs>
  );
}
