import { EnvEntry, Protocol } from "../_db/db";

export type GenericRequest =
  | {
      type: Protocol.HTTP;
      data: string;
      method: string;
      uri: string;
      headers: Record<string, string>;
    }
  | {
      type: Protocol.SIO;
      uri: string;
      headers: Record<string, string>;
    }
  | {
      type: Protocol.WS;
      uri: string;
      headers: Record<string, string>;
    };

export const replaceRequestWithEnvVar = (
  req: GenericRequest,
  env: EnvEntry
) => {
  Object.entries(env.data.kvList).forEach(([id, envItem]) => {
    req.uri = req.uri.replaceAll(`[[${envItem.key}]]`, envItem.value);

    req.headers = Object.entries(req.headers).reduce((acc, [key, value]) => {
      let newKey = key.replaceAll(`[[${envItem.key}]]`, envItem.value);
      let newValue = value.replaceAll(`[[${envItem.key}]]`, envItem.value);
      return { ...acc, [newKey]: newValue };
    }, {});

    if (req.type === Protocol.HTTP) {
      req.data = req.data.replaceAll(`[[${envItem.key}]]`, envItem.value);
    }
  });

  if (req.type === Protocol.HTTP)
    return { ...req, url: req.uri };
  return { ...req};
};
