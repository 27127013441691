import { ActionIcon, Button, Group, Input, Modal, Select } from "@mantine/core";
import {
  AB2,
  AlertTriangle,
  Bookmark as Saved,
  World,
} from "tabler-icons-react";
import classes from "../Websocket.module.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnvType, Protocol } from "../../../data/_db/db";
import {
  getAllEntriesFromEnv,
  getEntryFromEnvById,
} from "../../../data/_db/repositoryEnv";
import { addEntryToHistory } from "../../../data/_db/repositoryHistory";
import {
  CollectionDetails,
  removeSelectedNode,
  updateData as updateDataForCollection,
} from "../../../data/_store/CollectionSlice";
import { RoutingInfoType } from "../../../data/_store/RoutingSlice";
import {
  WebsocketDetails,
  cleanErrMessage,
  connectWs,
  disconnectWS,
  setEnv,
  setUri,
} from "../../../data/_store/WebsocketSlice";
import { RootState } from "../../../data/_store/store";
import CollectionBar from "../../../helpers/CollectionBar";
import SaveRequestModal from "../../../helpers/SaveRequestModal";

export default function WsHeader() {
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false);

  const wsDetails = useSelector<RootState, WebsocketDetails>(
    (state) => state.wsDetails
  );
  const routingInfo = useSelector<RootState, RoutingInfoType>(
    (state) => state.routingInfo
  );

  const dispatch = useDispatch<any>();
  const [envs, setEnvs] = useState<EnvType>([]);

  useEffect(() => {
    getAllEntriesFromEnv().then((envEntries) => {
      if (envEntries) setEnvs(envEntries);
    });
  }, []);

  useLayoutEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(removeSelectedNode());
      dispatch(disconnectWS());
    };
  }, []);

  useEffect(() => {
    if (wsDetails.connectionErrMsg) {
      openModal(true);
      dispatch(cleanErrMessage());
    }
  }, [wsDetails.connectionErrMsg]);

  useEffect(() => {
    dispatch(
      updateDataForCollection({
        protocol: Protocol.WS,
        data: buildSalvableData(),
      })
    );
  }, [wsDetails]);

  const validateURL = (url: string) => {
    if (url.length <= 5) {
      setInvalidUrl(true);
      return true;
    } else {
      setInvalidUrl(false);
      return false;
    }
  };

  const [opened, openModal] = useState(false);
  const [saveDialogOpened, setSaveDialogOpened] = useState(false);

  const collectionDetails = useSelector<RootState, CollectionDetails>(
    (state) => state.collectionDetails
  );

  const archiveWsDetailsInHisto = () => {
    addEntryToHistory({
      protocol: Protocol.WS,
      data: {
        uri: wsDetails.uri,
        crtMessage: "",
        messages: [],
        wsId: null,
        headers: wsDetails.headers,
        connection: "disconnected",
        env: wsDetails.env,
      },
    });
  };

  const archiveWsDetailsInSaved = () => {
    addEntryToHistory({
      protocol: Protocol.WS,
      data: buildSalvableData(),
    });
  };

  const buildSalvableData = (): WebsocketDetails => {
    return {
      wsId: null,
      uri: wsDetails.uri,
      crtMessage: "",
      messages: wsDetails.messages,
      headers: wsDetails.headers,
      connection: "disconnected",
      env: wsDetails.env,
    };
  };

  return (
    <>
      <SaveRequestModal
        opened={saveDialogOpened}
        setOpened={(value) => {
          setSaveDialogOpened(value);
        }}
        entryData={{
          protocol: Protocol.WS,
          data: buildSalvableData(),
        }}
        page={routingInfo.page}
      />
      <Group className={classes.requestDetails}>
        <Modal
          opened={opened}
          onClose={() => openModal(false)}
          title="Error while trying to connect"
          centered
        >
          <div className={classes.alert}>
            <AlertTriangle />
            The path provided is not available.
          </div>
        </Modal>
        <div className={classes.rdTitle}>
          <p className={`${classes.rdTitleText} ${classes.notSelectable}`}>
            WEBSOCKET
          </p>
        </div>
        <Input
          icon={<World />}
          placeholder="Your URL"
          className={classes.rdURI}
          value={wsDetails.uri}
          onChange={(event) => {
            const value = event.currentTarget.value;
            //TODO : change validation with sth like match
            validateURL(value);
            dispatch(setUri(value));
          }}
          error={invalidUrl}
          disabled={wsDetails.connection === "connected"}
        />
        <Select
          icon={<AB2 />}
          placeholder="Environment"
          value={wsDetails.env?.id}
          onChange={(value) => {
            if (value) {
              getEntryFromEnvById(value).then((env) => {
                dispatch(setEnv(env));
              });
            }
          }}
          data={envs
            .filter((env) => env.data.name)
            .map((env) => ({ value: env.id, label: env.data.name }))}
        />
        {wsDetails.connection === "connected" && (
          <ActionIcon
            onClick={() => {
              archiveWsDetailsInSaved();
              setSaveDialogOpened(true);
            }}
          >
            <Saved />
          </ActionIcon>
        )}
        <Button
          variant="gradient"
          gradient={{ from: "orange", to: "red" }}
          className={classes.rdSendButton}
          onClick={() => {
            if (wsDetails.connection !== "connected") {
              if (!validateURL(wsDetails.uri)) {
                dispatch(connectWs());
                archiveWsDetailsInHisto();
              } else {
                openModal(true);
              }
            } else {
              dispatch(disconnectWS());
            }
          }}
        >
          {wsDetails.connection === "connected" ? "DISCONNECT" : "CONNECT"}
        </Button>
      </Group>
      {collectionDetails.selectedCollection !== null && <CollectionBar />}
    </>
  );
}
