import { LoadingOverlay } from "@mantine/core";
import { useSelector } from "react-redux";
import { HttpDetails } from "../../data/_store/HttpMethodSlice";
import { RootState } from "../../data/_store/store";

import classes from "../Websocket/Websocket.module.css";
import WsHeader from "./WsHeader/WsHeader";
import Chat from "./Chat/Chat";
import InputBox from "./InputBox/InputBox";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
// import PanelGroup from "react-panelgroup";

export default function Websocket() {
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );

  return (
    <>
      <LoadingOverlay
        loaderProps={{ size: "xl", color: "blue", variant: "bars" }}
        overlayOpacity={0.6}
        overlayColor="#c5c5c5"
        visible={httpDetails.responseState === "loading"}
      />

      <div className={classes.rest}>
        <div className={classes.restContentWrapper}>
          <WsHeader />
          <div className={classes.panelWrapper}>
            <PanelGroup direction="vertical">
              <Panel minSize={20} className={classes.firstPanel}>
                <Chat />
              </Panel>
              <PanelResizeHandle />
              <Panel minSize={20} className={classes.sndPanel}>
                <InputBox />
              </Panel>
            </PanelGroup>
          </div>
        </div>
      </div>
    </>
  );
}
