import { ActionIcon, Button, Group, Input, Modal, Select } from "@mantine/core";
import {
  AB2,
  AlertTriangle,
  Bookmark as Saved,
  World,
} from "tabler-icons-react";
import classes from "../Sio.module.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnvType, Protocol } from "../../../data/_db/db";
import {
  getAllEntriesFromEnv,
  getEntryFromEnvById,
} from "../../../data/_db/repositoryEnv";
import { addEntryToHistory } from "../../../data/_db/repositoryHistory";
import {
  CollectionDetails,
  removeSelectedNode,
  updateData as updateDataForCollection,
} from "../../../data/_store/CollectionSlice";
import { RoutingInfoType } from "../../../data/_store/RoutingSlice";
import {
  SioDetails,
  cleanErrMessage,
  connectSio,
  setEnv,
  setUri,
} from "../../../data/_store/SioSlice";
import { RootState } from "../../../data/_store/store";
import { sioArray } from "../../../data/connections";
import CollectionBar from "../../../helpers/CollectionBar";
import SaveRequestModal from "../../../helpers/SaveRequestModal";

export default function SioHeader() {
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );
  const routingInfo = useSelector<RootState, RoutingInfoType>(
    (state) => state.routingInfo
  );
  const [envs, setEnvs] = useState<EnvType>([]);

  const dispatch = useDispatch<any>();

  useLayoutEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      closeSocket();
      dispatch(removeSelectedNode());
    };
  }, []);

  useEffect(() => {
    getAllEntriesFromEnv().then(
      (envEntries) => envEntries && setEnvs(envEntries)
    );
  }, []);
  useEffect(() => {
    if (sioDetails.connectionErrMsg) {
      openModal(true);
      dispatch(cleanErrMessage());
    }
  }, [sioDetails.connectionErrMsg]);

  useEffect(() => {
    dispatch(
      updateDataForCollection({
        protocol: Protocol.SIO,
        data: buildSalvableData(),
      })
    );
  }, [sioDetails]);

  const closeSocket = () => {
    sioArray
      .find(({ sioId, functions }) => sioId === sioDetails.sioId)
      ?.functions.closeSio();
  };

  const [opened, openModal] = useState(false);
  const buildSalvableData = (): SioDetails => {
    return {
      sioId: null,
      uri: sioDetails.uri,
      crtMessage: "",
      messages: sioDetails.messages,
      headers: sioDetails.headers,
      connection: "disconnected",
      receivingChannels: sioDetails.receivingChannels,
      emittingChannels: sioDetails.emittingChannels,
      crtEmittingChannel: sioDetails.crtEmittingChannel,
      env: sioDetails.env,
    };
  };

  const [saveDialogOpened, setSaveDialogOpened] = useState(false);

  const collectionDetails = useSelector<RootState, CollectionDetails>(
    (state) => state.collectionDetails
  );

  const archiveSioDetailsInHisto = () => {
    addEntryToHistory({
      protocol: Protocol.SIO,
      data: {
        uri: sioDetails.uri,
        crtMessage: "",
        messages: [],
        sioId: null,
        headers: sioDetails.headers,
        connection: "disconnected",
        emittingChannels: [],
        receivingChannels: {},
        crtEmittingChannel: "",
        env: sioDetails.env,
      },
    });
  };

  return (
    <>
      <SaveRequestModal
        opened={saveDialogOpened}
        setOpened={(value) => {
          setSaveDialogOpened(value);
        }}
        entryData={{
          protocol: Protocol.SIO,
          data: buildSalvableData(),
        }}
        page={routingInfo.page}
      />
      <Group className={classes.requestDetails}>
        <Modal
          opened={opened}
          onClose={() => openModal(false)}
          title="Error while trying to connect"
          centered
        >
          <div className={classes.alert}>
            <AlertTriangle />
            The path provided is not available.
          </div>
        </Modal>
        <div className={classes.rdTitle}>
          <p className={`${classes.rdTitleText} ${classes.notSelectable}`}>
            SOCKET IO
          </p>
        </div>
        <Input
          icon={<World />}
          placeholder="Your URL"
          className={classes.rdURI}
          value={sioDetails.uri}
          onChange={(event) => {
            const value = event.currentTarget.value;
            //TODO : change validation with sth like match
            dispatch(setUri(value));
          }}
          disabled={sioDetails.connection === "connected"}
        />
        <Select
          icon={<AB2 />}
          placeholder="Environment"
          value={sioDetails.env?.id}
          onChange={(value) => {
            if (value) {
              getEntryFromEnvById(value).then((env) => {
                dispatch(setEnv(env));
              });
            }
          }}
          data={envs
            .map((env) =>
              env.data.name
                ? { value: env.id, label: env.data.name }
                : { value: "", label: "" }
            )
            .filter(({ value, label }) => label !== "" && value !== "")}
        />
        {sioDetails.connection === "connected" && (
          <ActionIcon
            onClick={() => {
              setSaveDialogOpened(true);
            }}
          >
            <Saved />
          </ActionIcon>
        )}
        <Button
          variant="gradient"
          gradient={{ from: "orange", to: "red" }}
          className={classes.rdSendButton}
          onClick={() => {
            if (sioDetails.connection !== "connected") {
              dispatch(
                connectSio({
                  uri: sioDetails.uri,
                  headers: sioDetails.headers,
                })
              );
              archiveSioDetailsInHisto();
            } else {
              closeSocket();
            }
          }}
        >
          {sioDetails.connection === "connected" ? "DISCONNECT" : "CONNECT"}
        </Button>
      </Group>
      {collectionDetails.selectedCollection !== null && <CollectionBar />}
    </>
  );
}
