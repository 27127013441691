import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionData, EntryCollectionNode, Protocol } from "../_db/db";

export type CollectionDetails = {
  selectedCollection: EntryCollectionNode | null;
  selectedCollectionProtocol: Protocol | null;
};

const initialState: CollectionDetails = {
  selectedCollection: null,
  selectedCollectionProtocol: null,
};

export type CollectionInput = {
  collection: EntryCollectionNode;
  protocol: Protocol;
};

export const collectionSlice = createSlice({
  name: "envDetails",
  initialState: initialState,
  reducers: {
    setSelectedNode: (state, action: PayloadAction<CollectionInput>) => {
      state.selectedCollection = action.payload.collection;
      state.selectedCollectionProtocol = action.payload.protocol;
    },

    removeSelectedNode: (state) => {
      state.selectedCollection = null;
      state.selectedCollectionProtocol = null;
    },
    updateName: (state, action) => {
      if (state.selectedCollection !== null) {
        state.selectedCollection.name = action.payload;
      }
    },
    updateData: (state, action: PayloadAction<ConnectionData>) => {
      if (state.selectedCollection !== null) {
        state.selectedCollection.entryData = action.payload;
      }
    },
  },
});

export const { setSelectedNode, updateData, removeSelectedNode, updateName } =
  collectionSlice.actions;

export default collectionSlice.reducer;
