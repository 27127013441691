import { createSlice } from "@reduxjs/toolkit";

export type EnvItemType = {
  key: string;
  value: string;
  id: string;
  locked: boolean;
};

export interface EnvDetails {
  id: string | undefined;
  name: string;
  kvList: Record<string, EnvItemType>;
  hasUnsavedEdits:boolean;

}

const initialState: EnvDetails = {
  id: undefined,
  name: "",
  kvList: {},
  hasUnsavedEdits:false,
};

export const envSlice = createSlice({
  name: "envDetails",
  initialState: initialState,
  reducers: {
    addEnvItem: (state, action) => {
      state.kvList = { ...state.kvList, [action.payload.id]: action.payload };
      state.hasUnsavedEdits = true;
    },
    removeEnvItem: (state, action) => {
      const kvListCpy = { ...state.kvList };
      delete kvListCpy[action.payload];
      state.kvList = kvListCpy;
      state.hasUnsavedEdits = true;
    },
    loadEnv: (state, action) => {
      state.kvList = action.payload.kvList;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.hasUnsavedEdits = false;
    },
    updateName: (state, action) => {
      state.name = action.payload;
      state.hasUnsavedEdits = true;
    },
    editsWereSaved: (state) => {
      state.hasUnsavedEdits = false;
    },
  },
});

export const { addEnvItem, removeEnvItem, loadEnv, updateName,editsWereSaved } =
  envSlice.actions;

export default envSlice.reducer;
