import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Modal,
  NavLink,
  Radio,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { File, Folder, Plus } from "tabler-icons-react";
import { computeLabel } from "../NavBar/Drawers/Saved";
import {
  CollectionNode,
  CollectionType,
  ConnectionData,
  Protocol,
} from "../data/_db/db";
import {
  addEntryToCollections,
  getAllEntriesFromCollections,
} from "../data/_db/repositoryCollections";
import { addEntryToSaved } from "../data/_db/repositorySaved";
import { CollectionDetails } from "../data/_store/CollectionSlice";
import { PageType } from "../data/_store/RoutingSlice";
import { RootState } from "../data/_store/store";
import classes from "./Helpers.module.css";

export type SaveRequestModalProp = {
  opened: boolean;
  setOpened: (value: boolean) => void;
  entryData: ConnectionData;
  page: PageType;
};

export default function SaveRequestModal(props: SaveRequestModalProp) {
  const collectionDetails = useSelector<RootState, CollectionDetails>(
    (state) => state.collectionDetails
  );

  /***STATE VALUES */

  const [parent, setParent] = useState<CollectionNode | null>(null);
  const [path, setPath] = useState<CollectionNode[]>([
    {
      date: new Date(),
      id: "",
      name: "Collections",
      parent: null,
      type: CollectionType.DIRECTORY,
    },
  ]);
  const [crtChildren, setCrtChildren] = useState<CollectionNode[]>([]);
  const [folderName, setFolderName] = useState<string>("New Directory");
  const [fileName, setFileName] = useState<string>("");
  const [saveInCollection, setSaveInCollection] = useState<boolean>(false);

  /**** */

  /******USE EFFECT ********/
  useEffect(() => {
    updateLocation(null);
    setFileName(computeLabel(props.entryData));
  }, []);

  useEffect(() => {
    updateLocation(parent);
  }, [props.opened, folderName, parent, crtChildren]);

  const updateLocation = (parent: CollectionNode | null) => {
    setParent(parent);
    getAllEntriesFromCollections().then((data) => {
      data != undefined &&
        setCrtChildren(
          data.filter((elm) =>
            parent && parent.id !== ""
              ? elm.parent === parent.id
              : elm.parent === null
          )
        );
    });
  };

  const renderLevel = (children: CollectionNode[]) => {
    if (!children.length)
      return (
        <Text className={classes.emptyDirectory}>{"Empty directory"} </Text>
      );
    return children.map((element) => (
      <NavLink
        label={element.name}
        icon={element.type === CollectionType.ENTRY ? <File /> : <Folder />}
        key={element.id}
        disabled={element.type === CollectionType.ENTRY}
        onClick={() => {
          if (element.type === CollectionType.DIRECTORY) {
            updateLocation(element);
            if (path.filter((el) => el.id === element.id).length < 1) {
              path.push(element);
            }
          }
        }}
      ></NavLink>
    ));
  };

  const archiveEntryToSaved = () => {
    switch (props.page) {
      case Protocol.HTTP:
        addEntryToSaved({ ...props.entryData });
        break;
      case Protocol.WS:
        addEntryToSaved({ ...props.entryData });
        break;
      case Protocol.SIO:
        addEntryToSaved({ ...props.entryData });
        break;

      default:
        break;
    }
  };

  const getProtocol = (): ConnectionData | null => {
    if (
      props.page === Protocol.HTTP ||
      props.page === Protocol.SIO ||
      props.page === Protocol.WS
    ) {
      return { ...props.entryData };
    }
    return null;
  };

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        saveInCollection ? (
          <input
            placeholder="File Name"
            value={fileName}
            onChange={(event) => setFileName(event.target.value)}
            className={classes.fileName}
          />
        ) : (
          <input
            value={computeLabel(getProtocol())}
            className={classes.fileName}
            disabled
          />
        )
      }
    >
      <Radio.Group
        name="favoriteFramework"
        value={saveInCollection + ""}
        onChange={(ev) => {
          setSaveInCollection(ev === "true");
        }}
      >
        <Group mt="xs" className={classes.saveDialogHeader}>
          <Radio value={"true"} label="add to collection" />
          <Radio label="save" value={"false"} />
        </Group>
      </Radio.Group>

      {saveInCollection && (
        <div className={classes.saveInCollectionDialog}>
          <div className={classes.directoryPath}>
            <Breadcrumbs>
              {path.map((el, idx) => (
                <Anchor
                  key={el.id}
                  onClick={() => {
                    if (el.type === CollectionType.DIRECTORY) {
                      updateLocation(el);
                      setPath(path.slice(0, idx + 1));
                    }
                  }}
                >
                  {el.name}
                </Anchor>
              ))}
            </Breadcrumbs>
          </div>

          <div className={classes.newDirectoryBar}>
            <input
              placeholder="Folder Name"
              value={folderName}
              onChange={(event) => setFolderName(event.target.value)}
              className={classes.fileName}
            />
            <ActionIcon
              color="green"
              onClick={() => {
                addEntryToCollections(
                  CollectionType.DIRECTORY,
                  folderName,
                  !parent || parent.id === "" ? null : parent.id
                );

                setFolderName("New Directory");
              }}
            >
              <Plus />
            </ActionIcon>
          </div>
          <div className={classes.folderBox}>{renderLevel(crtChildren)}</div>
        </div>
      )}

      <div className={classes.buttons}>
        <Button
          color="green"
          className={classes.button}
          onClick={() => {
            saveInCollection
              ? addEntryToCollections(
                  CollectionType.ENTRY,
                  fileName,
                  !parent || parent.id === "" ? null : parent.id,
                  props.entryData
                )
              : archiveEntryToSaved();

            props.setOpened(false);
          }}
        >
          SAVE
        </Button>
        <Button
          color="red"
          className={classes.button}
          onClick={() => {
            props.setOpened(false);
          }}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
}
