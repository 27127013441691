import classes from "./Env.module.css";
import { ActionIcon, Input } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CirclePlus,
  Bookmark,
  Edit,
  Key,
  ListDetails,
  TrashX,
} from "tabler-icons-react";
import { RootState } from "../../data/_store/store";
import {
  addEnvItem,
  editsWereSaved,
  EnvDetails,
  removeEnvItem,
  updateName,
} from "../../data/_store/EnvSlice";
import { updateEntryFromEnvById } from "../../data/_db/repositoryEnv";

export default function PageEnv() {
  const [key, setKey] = useState("");
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch<any>();
  const envDetails = useSelector<RootState, EnvDetails>(
    (state) => state.envDetails
  );

  useEffect(() => {
    setEdit(false);
  }, [envDetails.id]);

  return (
    <>
      <div className={classes.headerArea}>
        <Input
          value={envDetails.name}
          onChange={(e) => dispatch(updateName(e.currentTarget.value))}
          placeholder="Name"
          className={classes.nameBox}
          disabled={!edit}
        />
        <ActionIcon
          color="blue"
          className={classes.actionButton}
          onClick={() => {
            setEdit(true);
          }}
          disabled={edit}
        >
          <Edit />
        </ActionIcon>
        <ActionIcon
          color="blue"
          className={classes.actionButton}
          onClick={() => {
            if (envDetails.id)
              updateEntryFromEnvById(
                envDetails.id,
                envDetails.name,
                envDetails.kvList
              );

            setEdit(false);
            dispatch(editsWereSaved());
          }}
          disabled={
            envDetails.id === undefined || envDetails.hasUnsavedEdits === false
          }
        >
          <Bookmark />
        </ActionIcon>
      </div>

      <div className={classes.envGrid}>
        <div className={classes.envGridHeader}>
          <Input
            icon={<Key />}
            value={key}
            onChange={(e) => setKey(e.currentTarget.value)}
            placeholder="Key"
            className={classes.envGridHeaderInput}
          />
          <ActionIcon
            color="blue"
            className={classes.actionButtonAdd}
            onClick={() => {
              dispatch(
                addEnvItem({
                  key,
                  value: "",
                  id: crypto.randomUUID(),
                  locked: false,
                })
              );
              setKey("");
            }}
            disabled={key == ""}
          >
            <CirclePlus />
          </ActionIcon>
        </div>
        {Object.entries(envDetails.kvList).map(([id, data]) => {
          return (
            <div className={classes.envGridRow} key={id}>
              <Input
                icon={<Key />}
                value={data.key}
                onChange={(e) => {
                  const newData = { ...data, key: e.currentTarget.value };
                  dispatch(addEnvItem(newData));
                }}
                placeholder="Key"
                className={classes.envGridRowElement}
              />
              <Input
                icon={<ListDetails />}
                value={data.value}
                onChange={(e) => {
                  const newData = { ...data, value: e.currentTarget.value };
                  dispatch(addEnvItem(newData));
                }}
                placeholder="Value"
                className={classes.envGridRowElement}
              />
              <ActionIcon
                color="red"
                onClick={() => {
                  dispatch(removeEnvItem(data.id));
                }}
              >
                <TrashX />
              </ActionIcon>
            </div>
          );
        })}
      </div>
    </>
  );
}
