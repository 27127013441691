import { isEmpty } from "lodash";
import { QueryParam } from "./data/_store/HttpMethodSlice";

export const replacePathParams = (
  url: string,
  pathParams: Record<string, string>
): string => {
  let myURL: string = url;
  if (!isEmpty(pathParams)) {
    Object.entries(pathParams).map(
      ([key, value]) => (myURL = myURL.replaceAll("{" + key + "}", value))
    );
  }

  return myURL;
};

export const appendQueryParams = (
  uri: string,
  queryParams: QueryParam[]
): string => {
  const query = queryParams.map((queryParams) =>
    `${encodeURIComponent(queryParams.key)}=${encodeURIComponent(queryParams.value)}`
  ).join('&');
  if (query.length === 0) {
    return uri;
  }
  return uri + "?" + query;
};
