import { ActionIcon, Input, Pagination } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CirclePlus,
  Refresh,
  Search
} from "tabler-icons-react";
import { EnvEntry, EnvType } from "../../../data/_db/db";
import {
  addEntryToEnv,
  getEntryFromEnvFromIndex,
  getFilteredEntryFromEnvFromIndex,
  nrOfEntriesInEnv,
  nrOfFilteredEntriesInEnv
} from "../../../data/_db/repositoryEnv";
import {
  DrawerPaginationInfo,
  setEnvCurrentPage,
} from "../../../data/_store/DrawerSlice";
import { EnvDetails, loadEnv } from "../../../data/_store/EnvSlice";
import { setPage } from "../../../data/_store/RoutingSlice";
import { RootState } from "../../../data/_store/store";
import classes from "../Drawers.module.css";
import EnvEntryElement from "./EnvEntryElement";

export default function Env() {
  const [filterMask, setFilterMask] = useState("");

  const [env, setEnv] = useState<EnvType>([]);
  const [envSize, setEnvSize] = useState(0);
  const envs = useSelector<RootState, EnvDetails>((state) => state.envDetails);
  const dispatch = useDispatch<any>();
  const [selected, setSelected] = useState("");

  const envStoreData = useSelector<RootState, DrawerPaginationInfo>(
    (state) => state.drawerInfo.env
  );

  const getEnvData = async () => {
    const envEntries = await getEntryFromEnvFromIndex(
      envStoreData.currentPage,
      envStoreData.nrOfElementsOnDisplay
    );

    if (envEntries) {
      setEnv(envEntries);
      setEnvSize(await nrOfEntriesInEnv());
    }
  };

  const getFilteredData = async (filter: string) => {
    const envEntries = await getFilteredEntryFromEnvFromIndex(
      envStoreData.currentPage,
      envStoreData.nrOfElementsOnDisplay,
      filterMask
    );

    if (envEntries) {
      setEnv(envEntries);
      setEnvSize(await nrOfFilteredEntriesInEnv(filterMask));
    }
  };

  useEffect(() => {
    getEnvData();
  }, []);

  useEffect(() => {
    getEnvData();
  }, [envs.hasUnsavedEdits, envStoreData.currentPage]);

  useEffect(() => {
    getFilteredData(filterMask);
  }, [filterMask]);

  const loadRequest = (envEntry: EnvEntry) => {
    dispatch(setPage(envEntry.type));
    dispatch(loadEnv({ ...envEntry.data, id: envEntry.id }));
  };
  return (
    <>
      <div className={classes.addBar}>
        <Input
          icon={<Search />}
          value={filterMask}
          onChange={(e) => setFilterMask(e.currentTarget.value)}
          placeholder="Search"
          className={classes.inputAddSearch}
        />
        <ActionIcon
          color="blue"
          className={classes.addButton}
          onClick={() => {
            addEntryToEnv("env", {
              name: filterMask,
              kvList: {},
            });
            setFilterMask("");
          }}
          disabled={filterMask === ""}
        >
          <CirclePlus />
        </ActionIcon>
      </div>
      <>
        <div className={classes.historyGroup}>
          <ActionIcon onClick={() => getFilteredData(filterMask)}>
            <Refresh size="1rem" />
          </ActionIcon>

          <Pagination
            value={envStoreData.currentPage}
            onChange={(selectedPage) => {
              dispatch(setEnvCurrentPage(selectedPage));
            }}
            boundaries={0}
            grow={false}
            siblings={1}
            noWrap={true}
            size="xs"
            style={{ maxWidth: "100px" }}
            total={Math.ceil(envSize / envStoreData.nrOfElementsOnDisplay)}
            withControls={true}
            withEdges={true}
          ></Pagination>
        </div>

        {env.length > 0 &&
          env.map((entry) => {
            return (
              <EnvEntryElement
                entry={entry}
                selected={selected}
                filterMask={filterMask}
                setSelected={setSelected}
                getFilteredData={getFilteredData}
                loadRequest={loadRequest}
              />
            );
          })}
      </>
    </>
  );
}
