import {
  CollectionNode,
  CollectionType,
  ConnectionData,
  db
} from "./db";

export const getEntryFromCollectionsById = async (id: string) => {
  try {
    const response = await db.collections.get(id);
    return response;
  } catch (error) {
    console.log("Errr getEntryFromCollectionsById");
  }
};
export const getAllEntriesFromCollectionsLQ = () => db.collections.orderBy("date").reverse().toArray()
export const getAllEntriesFromCollections = async () => {
  try {
    const response = await db.collections.orderBy("date").reverse().toArray();
    return response;
  } catch (error) {
    console.log("Errr getAllEntriesFromCollections", error);
  }
};
export const getEntryFromCollectionsFromIndex = async (
  startIndex: number,
  nrOfItems: number
) => {
  try {
    const response = await db.collections
      .orderBy("date")
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getEntryFromCollectionsFromIndex");
  }
};

export const getFilteredEntryFromCollectionsFromIndex = async (
  startIndex: number,
  nrOfItems: number,
  searchedParam: string
) => {
  try {
    const response = await db.collections
      .orderBy("date")
      .filter((entry) => filterEntry(entry, searchedParam))
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getFilteredEntryFromCollectionsFromIndex");
  }
};

export const deleteEntryFromCollectionsById = async (id: string) => {
  try {
    const response = await db.collections.delete(id);
    return response;
  } catch (error) {
    console.log("Errr deleteEntryFromCollectionsById");
  }
};

export const updateEntryFromCollectionsById = async (
  collectionNode: CollectionNode
) => {
  try {
    const response = await db.collections.update(collectionNode.id, { ...collectionNode });
    return response;
  } catch (error) {
    console.log("Errr updateEntryFromCollectionsById");
  }
};

export const addEntryToCollections = async (
  type: CollectionType,
  name: string,
  parent: string | null,
  entryData?: ConnectionData
) => {
  try {
    const date = new Date();
    const id: string = crypto.randomUUID();

    const entry: CollectionNode =
      type === CollectionType.ENTRY && entryData
        ? { id, type, date, name, parent, entryData }
        : { id, type: CollectionType.DIRECTORY, date, name, parent };

    await db.collections.add(entry);
  } catch (error) {
    console.log("Errr addEntryToCollections", error);
  }
};

export const nrOfEntriesInCollections = async (): Promise<number> => {
  try {
    const size = await db.collections.count();
    return size;
  } catch (error) {
    console.log("Errr nrOfEntriesInCollections");
  }
  return 0;
};
export const nrOfFilteredEntriesInCollections = async (
  searchedParam: string
): Promise<number> => {
  try {
    const size = await db.collections
      .filter((entry) => filterEntry(entry, searchedParam))
      .count();
    return size;
  } catch (error) {
    console.log("Errr nrOfFilteredEntriesInCollections");
  }
  return 0;
};

const filterEntry = (entry: CollectionNode, searchedParam: string) => {
  return entry.name
    ? entry.name.toLowerCase().includes(searchedParam.toLowerCase())
    : false;
};
