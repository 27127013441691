import { computeLabel } from "../../NavBar/Drawers/Saved";
import { db, ArchiveEntry, ConnectionData } from "./db";


export const nrOfEntriesInSavedLQ = () => db.saved.count();


export const getEntryFromSavedById = async (id: number) => {
  try {
    const response = await db.saved.get(id);
    return response;
  } catch (error) {
    console.log("Errr getEntryFromSavedById");
  }
};

export const getAllEntriesFromSaved = async () => {
  try {
    const response = await db.saved.orderBy("date").reverse().toArray();
    return response;
  } catch (error) {
    console.log("Errr getAllEntriesFromSaved", error);
  }
};
export const getEntryFromSavedFromIndex = async (
  startIndex: number,
  nrOfItems: number
) => {
  try {
    const response = await db.saved
      .orderBy("date")
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getEntryFromSavedFromIndex");
  }
};

export const getFilteredEntryFromSavedFromIndex = async (
  startIndex: number,
  nrOfItems: number,
  searchedParam: string
) => {
  try {
    const response = await db.saved
      .orderBy("date")
      .filter((entry) => filterEntry(entry, searchedParam))
      .reverse()
      .offset((startIndex - 1) * nrOfItems)
      .limit(nrOfItems)
      .toArray();
    return response;
  } catch (error) {
    console.log("Errr getFilteredEntryFromSavedFromIndex");
  }
};
export const deleteEntryFromSavedById = async (id: string) => {
  try {
    const response = await db.saved.delete(id);
    return response;
  } catch (error) {
    console.log("Errr deleteEntryFromSavedById");
  }
};

export const addEntryToSaved = async (savedEntryData: ConnectionData) => {
  try {
    const date = new Date();
    const id: string = crypto.randomUUID();
    await db.saved.add({ id, date, ...savedEntryData });
  } catch (error) {
    console.log("Errr addEntryToSaved", error);
  }
};

export const nrOfEntriesInSaved = async (): Promise<number> => {
  try {
    const size = await db.saved.count();
    return size;
  } catch (error) {
    console.log("Errr nrOfEntriesInSaved");
  }
  return 0;
};
export const nrOfFilteredEntriesInSaved = async (
  searchedParam: string
): Promise<number> => {
  try {
    const size = await db.saved
      .filter((entry) => filterEntry(entry, searchedParam))
      .count();
    return size;
  } catch (error) {
    console.log("Errr nrOfFilteredEntriesInSaved");
  }
  return 0;
};

const filterEntry = (entry: ArchiveEntry, searchedParam: string) => {
  return computeLabel({
    protocol: entry.protocol,
    data: entry.data,
  } as ConnectionData)
    .toLowerCase()
    .includes(searchedParam.toLowerCase());
};
