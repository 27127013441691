import { io } from "socket.io-client";
import { MessageType } from "../../data/_store/WebsocketSlice";
import { sioArray } from "../../data/connections";
export type SocketIOConnection = {
  sendMessage: (message: string, emittingChannel: string) => void;
  closeSio: () => void;
};

export default async function connectToSIO(
  dispatchMessage: (
    message: string,
    type: MessageType,
    channel: string
  ) => void,
  dispatchReceivingChannel: (channel: String) => void,
  disconnectSocketIo: (message: string) => void,
  uri: string,
  headers: Record<string, string>
) {
  //give an initial state so that the data won't be undefined at start
  return new Promise<string>((resolve, reject) => {
    const id = crypto.randomUUID();

    setTimeout(() => {
      try {
        const ws = io(uri, {
          reconnection: false, // Disable automatic reconnection
          extraHeaders: headers,
        });

        ws.on("connect", () => {
          resolve(id);

          sioArray.push({
            sioId: id,
            functions: { sendMessage, closeSio },
          });
        });

        ws.on("connect_error", (err) => {
          reject("The path specified was not reachable");
        });

        ws.onAny((event, message) => {
          dispatchReceivingChannel(event);
          dispatchMessage(message, "received", event);
        });

        ws.on("disconnect", (message) => {
          if (
            message.includes("io client disconnect") ||
            message.includes("transport close")
          ) {
            disconnectSocketIo("Disconnect from server");
          } else {
            reject(message);
          }
        });

        const sendMessage = (message: string, crtEmittingChannel: string) => {
          try {
            ws.emit(crtEmittingChannel, message);
            dispatchMessage(message, "sent", crtEmittingChannel);
          } catch (err) {
            console.log(err);
          }
        };

        const closeSio = () => {
          try {
            ws.close();
          } catch (err) {
            console.log(err);
          }
        };
      } catch (err) {
        return reject(err);
      }
    }, 0);
  });
}
