import { useIntersection } from "@mantine/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { subscribeToEvent, unsubscribeToEvent } from "../../helpers/events";
export type InfiniteScrollProps = {
  nrOfElementsOnDisplay: number;
  batchNumber: number;
  nrOfElementsInDB: number;
  elementHeight: number;
  data: any[];
  builderFunction: (entry: any) => any;
  onBoundaryCall: (
    offset: number,
    numberOfElements: number,
    next: boolean,
    replace: boolean
  ) => void;
};

const currentBatch = (
  offset: number,
  nrOfElementsInDB: number,
  nrOfElementsOnDisplay: number,
  batchNumber: number,
  nextBatch: boolean
) => {
  if (nrOfElementsInDB < nrOfElementsOnDisplay) return 0;
  if (nextBatch) {
    if (offset + batchNumber >= nrOfElementsInDB)
      return nrOfElementsInDB - offset;
    return batchNumber;
  } else {
    if (offset - nrOfElementsOnDisplay - batchNumber < 0)
      return offset - nrOfElementsOnDisplay;
    return batchNumber;
  }
};

const prevOffset = (
  offset: number,
  batchNumber: number,
  nrOfElementsOnDisplay: number
): number =>
  offset - nrOfElementsOnDisplay - batchNumber < 0
    ? 0
    : offset - nrOfElementsOnDisplay - batchNumber;

const futureOffset = (offset: number, batchNumber: number, next: boolean) => {
  return next ? offset + batchNumber : offset - batchNumber;
};

const getTopPadding = (
  topPadding: number,
  batchNumber: number,
  next: boolean
) => {
  if (next) {
    return topPadding + batchNumber;
  }
  return topPadding - batchNumber > 0 ? topPadding - batchNumber : 0;
};

const getBottomPadding = (
  bottomPadding: number,
  batchNumber: number,
  next: boolean
) => {
  if (next) {
    return bottomPadding - batchNumber > 0 ? bottomPadding - batchNumber : 0;
  }
  return bottomPadding + batchNumber;
};

export default function InfiniteScroll({
  nrOfElementsOnDisplay,
  batchNumber,
  nrOfElementsInDB,
  elementHeight,
  onBoundaryCall,
  data,
  builderFunction,
}: InfiniteScrollProps) {
  const [offset, setOffset] = useState(0);
  const [loadingPageDirection, setLoadingPageDirection] = useState<{
    direction: "next" | "prev" | "first" | "initial";
    batch: number;
  }>({ direction: "initial", batch: nrOfElementsOnDisplay });
  const [tuple, setTuple] = useState({
    data: ["a"],
    paddingTop: 0,
    paddingBottom: 0,
  });

  const loadPrevBatch = useCallback(() => {
    const pOffset = prevOffset(offset, batchNumber, nrOfElementsOnDisplay);
    const batch = currentBatch(
      offset,
      nrOfElementsInDB,
      nrOfElementsOnDisplay,
      batchNumber,
      false
    );
    onBoundaryCall(pOffset, batch, false, false);
    setOffset(futureOffset(offset, batch, false));

    setLoadingPageDirection({ direction: "prev", batch });
  }, [
    offset,
    onBoundaryCall,
    setOffset,
    batchNumber,
    nrOfElementsInDB,
    nrOfElementsOnDisplay,
  ]);

  const loadFirstBatch = useCallback(() => {
    const batch =
      nrOfElementsOnDisplay > nrOfElementsInDB
        ? nrOfElementsInDB
        : nrOfElementsOnDisplay;
    onBoundaryCall(offset, batch, true, false);
    setOffset(futureOffset(offset, batch, true));
    setLoadingPageDirection({ direction: "first", batch });
  }, [
    offset,
    onBoundaryCall,
    setOffset,
    nrOfElementsInDB,
    nrOfElementsOnDisplay,
  ]);

  const loadNextBatch = useCallback(() => {
    const batch = currentBatch(
      offset,
      nrOfElementsInDB,
      nrOfElementsOnDisplay,
      batchNumber,
      true
    );
    onBoundaryCall(offset, batch, true, false);
    setOffset(futureOffset(offset, batch, true));
    setLoadingPageDirection({ direction: "next", batch });
  }, [
    offset,
    onBoundaryCall,
    setOffset,
    batchNumber,
    nrOfElementsInDB,
    nrOfElementsOnDisplay,
  ]);

  const containerRef = useRef<HTMLDivElement>(null);
  const { ref: loadingNext, entry: nextEntry } =
    useIntersection<HTMLDivElement>({
      rootMargin: "0px",
      threshold: 0,
    });

  const { ref: loadingPrev, entry: prevEntry } =
    useIntersection<HTMLDivElement>({
      rootMargin: "0px",
      threshold: 0,
    });

  useEffect(() => {
    loadFirstBatch();
  }, []);

  useEffect(() => {
    const reloadAfterDbUpdate = async () => {
      if (offset > nrOfElementsInDB) {
        await onBoundaryCall(
          nrOfElementsInDB - nrOfElementsOnDisplay,
          nrOfElementsOnDisplay,
          true,
          false
        );
      } else {
        await onBoundaryCall(
          offset - nrOfElementsOnDisplay,
          nrOfElementsOnDisplay,
          true,
          false
        );
      }
    };
    subscribeToEvent("onDbUpdate", reloadAfterDbUpdate);
    return () => {
      unsubscribeToEvent("onDbUpdate", reloadAfterDbUpdate);
    };
  }, [data, offset, onBoundaryCall, nrOfElementsOnDisplay, nrOfElementsInDB]);

  useEffect(() => {
    if (nextEntry && nextEntry.isIntersecting) {
      if (nrOfElementsInDB > offset) {
        loadNextBatch();
      }
    }
  }, [nextEntry, nrOfElementsInDB]);

  useEffect(() => {
    if (prevEntry && prevEntry.isIntersecting) {
      if (offset > nrOfElementsOnDisplay) {
        loadPrevBatch();
      }
    }
  }, [prevEntry, nrOfElementsOnDisplay]);

  useEffect(() => {
    if (loadingPageDirection.direction == "first") {
      const paddingTop = 0;
      const paddingBottom = 0;
      setTuple({
        data,
        paddingBottom,
        paddingTop,
      });
    } else if (loadingPageDirection.direction != "initial") {
      const paddingTop = getTopPadding(
        tuple.paddingTop,
        loadingPageDirection.batch,
        loadingPageDirection.direction === "next"
      );
      const paddingBottom = getBottomPadding(
        tuple.paddingBottom,
        loadingPageDirection.batch,
        loadingPageDirection.direction === "next"
      );
      setTuple({
        data,
        paddingBottom,
        paddingTop,
      });
      //TODO: check if needed
      // setLoadingPageDirection({ direction: "initial", batch: batchNumber });
    }
  }, [data]);

  return (
    <>
      <div ref={containerRef}>
        {offset > nrOfElementsOnDisplay && tuple.data.length > 0 && (
          <div
            ref={loadingPrev}
            id="loadingPrev"
            style={{
              paddingTop: tuple.paddingTop * elementHeight + "px",

              height: elementHeight,
            }}
          ></div>
        )}
        {tuple.data.map((entry, idx) => {
          return builderFunction(entry);
        })}
        {offset < nrOfElementsInDB &&
          tuple.data.length >= nrOfElementsOnDisplay && (
            <div
              ref={loadingNext}
              id="loadingNext"
              style={{
                paddingBottom: tuple.paddingBottom * elementHeight + "px",
                height: elementHeight,
              }}
            ></div>
          )}
      </div>
    </>
  );
}
