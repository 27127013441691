import { ActionIcon, Button, Group, Input, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AB2, Bookmark as Saved, World } from "tabler-icons-react";
import { EnvType, Protocol } from "../../../data/_db/db";
import {
  getAllEntriesFromEnv,
  getEntryFromEnvById,
} from "../../../data/_db/repositoryEnv";
import { addEntryToHistory } from "../../../data/_db/repositoryHistory";
import {
  CollectionDetails,
  removeSelectedNode,
  updateData as updateDataForCollection,
} from "../../../data/_store/CollectionSlice";
import {
  AxiosRequestData,
  HttpDetails,
  cleanResp,
  fetchResponse,
  restoreToInitialValue,
  setEnv,
  setHttpMethod,
  setQueryParams,
  setUri,
} from "../../../data/_store/HttpMethodSlice";
import { RoutingInfoType } from "../../../data/_store/RoutingSlice";
import { RootState } from "../../../data/_store/store";
import CollectionBar from "../../../helpers/CollectionBar";
import SaveRequestModal from "../../../helpers/SaveRequestModal";
import { appendQueryParams, replacePathParams } from "../../../utils";
import classes from "../Rest.module.css";

export default function RequestDetails() {
  const dispatch = useDispatch<any>();

  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );
  const routingInfo = useSelector<RootState, RoutingInfoType>(
    (state) => state.routingInfo
  );
  const [url, setUrl] = useState<string>("");
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false);
  const [envs, setEnvs] = useState<EnvType>([]);

  useEffect(() => {
    getAllEntriesFromEnv().then((envEntries) => {
      if (envEntries) setEnvs(envEntries);
    });
    return () => {
      // Clear the page after leaving
      dispatch(removeSelectedNode());
      dispatch(restoreToInitialValue());
    };
  }, []);

  useEffect(() => {
    const newUrl = appendQueryParams(httpDetails.uri, httpDetails.queryParams);
    if (newUrl !== url) {
      setUrl(newUrl);
    }
  }, [httpDetails.uri, httpDetails.queryParams]);

  useEffect(() => {
    dispatch(
      updateDataForCollection({ protocol: Protocol.HTTP, data: httpDetails })
    );
  }, [httpDetails]);

  const changeURI = (value: string) => {
    setUrl(value);
    try {
      const url = new URL(value);
      const qp = Array.from(url.searchParams.entries()).map(([key, value]) => ({
        key,
        value,
      }));
      if (JSON.stringify(qp) !== JSON.stringify(httpDetails.queryParams)) {
        dispatch(setQueryParams(qp));
      }

      const newUri = value.split("?")[0];
      if (newUri !== httpDetails.uri) {
        dispatch(setUri(newUri));
      }
      setInvalidUrl(false);
    } catch (err) {
      dispatch(setUri(value));
      setInvalidUrl(true);
    }
  };

  const request = () => {
    const axiosReq: AxiosRequestData = {
      method: httpDetails.httpMethod.toLowerCase(),
      url: appendQueryParams(
        replacePathParams(httpDetails.uri, httpDetails.pathParams),
        httpDetails.queryParams
      ),
      headers: httpDetails.headers,
      data: httpDetails.requestBody,
    };
    dispatch(fetchResponse(axiosReq));
  };

  const cleanPrevResp = () => {
    dispatch(cleanResp());
  };

  /************************************* */
  const [opened, setOpened] = useState(false);

  const collectionDetails = useSelector<RootState, CollectionDetails>(
    (state) => state.collectionDetails
  );

  const savedHttpDetailsInHisto = () => {
    addEntryToHistory({
      protocol: Protocol.HTTP,
      data: {
        httpMethod: httpDetails.httpMethod,
        uri: httpDetails.uri,
        headers: httpDetails.headers,
        queryParams: httpDetails.queryParams,
        pathParams: httpDetails.pathParams,
        requestBody: httpDetails.requestBody,
        env: httpDetails.env,
        responseHeaders: {},
        responseBody: "",
        responseState: "",
        responseCode: "",
      },
    });
  };

  return (
    <>
      <SaveRequestModal
        opened={opened}
        setOpened={(value) => {
          setOpened(value);
        }}
        entryData={{
          protocol: Protocol.HTTP,
          data: httpDetails,
        }}
        page={routingInfo.page}
      />
      <Group className={classes.requestDetails}>
        <div className={classes.rdTitle}>
          <p className={`${classes.rdTitleText} ${classes.notSelectable}`}>
            HTTP
          </p>
        </div>
        <Select
          searchable
          onSearchChange={(el) => dispatch(setHttpMethod(el))}
          searchValue={httpDetails.httpMethod}
          nothingFound="No options"
          placeholder="HTTP methods"
          defaultValue={httpDetails.httpMethod}
          data={["GET", "POST", "PUT", "DELETE"]}
          allowDeselect
          className={classes.rdHttpMethod}
        />
        <Input
          icon={<World />}
          placeholder="Your URL"
          className={classes.rdURI}
          value={url}
          onChange={(event) => changeURI(event.target.value)}
          error={invalidUrl}
        />
        <Select
          icon={<AB2 />}
          placeholder="Environment"
          value={httpDetails.env?.id}
          onChange={(value) => {
            if (value) {
              getEntryFromEnvById(value).then((env) => {
                dispatch(setEnv(env));
              });
            }
          }}
          data={envs
            .map((env) =>
              env.data.name
                ? { value: env.id, label: env.data.name }
                : { value: "", label: "" }
            )
            .filter(({ value, label }) => label !== "" && value !== "")}
        />
        {httpDetails.responseCode && (
          <ActionIcon
            onClick={() => {
              setOpened(true);
            }}
          >
            <Saved />
          </ActionIcon>
        )}
        <Button
          variant="gradient"
          gradient={{ from: "orange", to: "red" }}
          className={classes.rdSendButton}
          onClick={() => {
            cleanPrevResp();
            savedHttpDetailsInHisto();
            request();
          }}
          disabled={invalidUrl}
        >
          SEND
        </Button>
      </Group>
      {collectionDetails.selectedCollection !== null && <CollectionBar />}
    </>
  );
}
