import { ActionIcon, Box, NavLink } from "@mantine/core";
import { File, Folder, TrashX } from "tabler-icons-react";
import {
  CollectionNode,
  CollectionType,
  ConnectionData,
  EntryCollectionNode,
  Protocol
} from "../../data/_db/db";
import { loadRequest as loadHTTPInfo } from "../../data/_store/HttpMethodSlice";

import { IconChevronRight } from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteEntryFromCollectionsById,
  getAllEntriesFromCollectionsLQ
} from "../../data/_db/repositoryCollections";
import { setSelectedNode } from "../../data/_store/CollectionSlice";
import { setPage } from "../../data/_store/RoutingSlice";
import { loadSavedInfo as loadSioInfo } from "../../data/_store/SioSlice";
import { loadSavedInfo as loadWsInfo } from "../../data/_store/WebsocketSlice";
import classes from "./Drawers.module.css";

export default function Collections() {
  const dispatch = useDispatch<any>();

  const data = useLiveQuery(getAllEntriesFromCollectionsLQ) ?? [];

  const openPage = (element: EntryCollectionNode, cd: ConnectionData) => {
    switch (cd.protocol) {
      case Protocol.HTTP:
        dispatch(loadHTTPInfo(cd.data));
        dispatch(setPage(cd.protocol));
        dispatch(
          setSelectedNode({ collection: element, protocol: cd.protocol })
        );
        break;
      case Protocol.WS:
        dispatch(loadWsInfo(cd.data));
        dispatch(setPage(cd.protocol));
        dispatch(
          setSelectedNode({ collection: element, protocol: cd.protocol })
        );
        break;
      case Protocol.SIO:
        dispatch(loadSioInfo(cd.data));
        dispatch(setPage(cd.protocol));
        dispatch(
          setSelectedNode({ collection: element, protocol: cd.protocol })
        );
        break;
    }
  };

  const deleteSubElements = (parent: CollectionNode) => {
    const subElements: CollectionNode[] = [parent];

    const getChildren = (children: CollectionNode[] | null) => {
      if (children === null || children.length === 0) return;
      children.map((el) => {
        subElements.push(el);
        getChildren(data.filter((child) => el.id === child.parent));
      });
    };

    getChildren(data.filter((child) => parent.id === child.parent));

    subElements.map((element) => deleteEntryFromCollectionsById(element.id));
  };

  const renderElement = (
    element: CollectionNode,
    children: CollectionNode[]
  ) => {
    if (element.type === CollectionType.ENTRY)
      return (
        <div className={classes.directoryRow} key={element.id}>
          <NavLink
            label={element.name}
            icon={<File size="1rem" />}
            active={crtElement && element.id === crtElement.id}
            onClick={() => {
              setCrtElement(element);
              openPage(element, element.entryData);
            }}
            rightSection={
              <ActionIcon
                color="red"
                className={classes.deleteFolder}
                onClick={() => {
                  deleteEntryFromCollectionsById(element.id);
                }}
              >
                <TrashX />
              </ActionIcon>
            }
          ></NavLink>
        </div>
      );

    return (
      <div className={classes.directoryRow} key={element.id}>
        <div className={classes.treeElement}>
          <NavLink
            className={`${classes.treeNavLink}`}
            label={element.name}
            icon={<Folder size="1rem" />}
            childrenOffset={"sm"}
            active={crtElement && element.id === crtElement.id}
            onClick={() => {
              setCrtElement(element);
            }}
            rightSection={
              <div className={classes.directoryRow}>
                {children.length > 0 && (
                  <div className={classes.iconElement}>
                    <IconChevronRight
                      size="1rem"
                      stroke={1.5}
                      className={classes.arrow}
                    />
                  </div>
                )}
                <ActionIcon
                  color="red"
                  className={classes.iconElement}
                  onClick={() => {
                    deleteSubElements(element);
                  }}
                >
                  <TrashX />
                </ActionIcon>
              </div>
            }
          >
            {children.length > 0 && renderTree(children)}
          </NavLink>
        </div>
      </div>
    );
  };

  const renderTree = (children: CollectionNode[]) => {
    return children.map((elm) =>
      renderElement(
        elm,
        data.filter((elm2) => elm2.parent === elm.id)
      )
    );
  };

  const [crtElement, setCrtElement] = useState<any>(null);

  return (
    <>
      <Box className={classes.collectionTree}>
        {renderTree(data.filter((elm) => elm.parent == null))}
      </Box>
    </>
  );
}
