import { LoadingOverlay } from "@mantine/core";
import classes from "./Rest.module.css";
import TabsInput from "./TabsInput/TabsInput";
import TabsOutput from "./TabsOutput/TabsOutput";
import RequestDetails from "./RequestDetails/RequestDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../data/_store/store";
import { HttpDetails } from "../../data/_store/HttpMethodSlice";

export default function Rest() {
  const httpDetails = useSelector<RootState, HttpDetails>(
    (state) => state.httpDetails
  );

  return (
    <>
      <LoadingOverlay
        loaderProps={{ size: "xl", color: "blue", variant: "bars" }}
        overlayOpacity={0.6}
        overlayColor="#c5c5c5"
        visible={httpDetails.responseState === "loading"}
      />

      <div className={classes.rest}>
        <div className={classes.restContentWrapper}>
          <RequestDetails />
          <div className={classes.tabsGroup}>
            <TabsInput />
            <TabsOutput />
          </div>
        </div>
      </div>
    </>
  );
}
