import { useDispatch, useSelector } from "react-redux";
import classes from "../../Sio.module.css";
import { RootState } from "../../../../data/_store/store";
import {
  MessageDataType,
  removeNthMessage,
  SioDetails,
  updateReceivingChannel,
} from "../../../../data/_store/SioSlice";
import {
  Settings2,
  PhoneIncoming,
  PhoneOutgoing,
  TrashX,
  VolumeOff,
  Volume,
} from "tabler-icons-react";
import {
  ActionIcon,
  Button,
  Menu,
} from "@mantine/core";

export default function Chat() {
  const dispatch = useDispatch<any>();
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );

  const sentMessage = (messageData: MessageDataType, idx: number) => {
    return (
      <div key={crypto.randomUUID()} className={`${classes.chatSentMessage}`}>
        <div className={classes.chatDetails}>
          <div className={classes.messageChannel}>
            <PhoneOutgoing className={classes.chatIcon} />
            {messageData.channel}
          </div>

          <Menu
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            disabled={sioDetails.connection !== "connected"}
          >
            <Menu.Target>
              <ActionIcon
                className={classes.deleteMessage}
                disabled={sioDetails.connection !== "connected"}
              >
                <Settings2 className={classes.chatIcon} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown className={classes.dropdownData}>
              <Button
                variant="subtle"
                className={classes.deleteMessageButton}
                onClick={() => {
                  dispatch(removeNthMessage(idx));
                }}
                leftIcon={<TrashX />}
              >
                {"Delete message"}
              </Button>
            </Menu.Dropdown>
          </Menu>
        </div>
        <div className={classes.message}> {messageData.message} </div>
      </div>
    );
  };

  const receivedMessage = (messageData: MessageDataType, idx: number) => {
    return (
      <div
        key={crypto.randomUUID()}
        className={`${classes.chatReceivedMessage}`}
      >
        <div className={classes.chatDetails}>
          <div className={classes.messageChannel}>
            <PhoneIncoming className={classes.chatIcon} />
            {messageData.channel}
          </div>

          <Menu
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            disabled={sioDetails.connection !== "connected"}
          >
            <Menu.Target>
              <ActionIcon
                className={classes.deleteMessage}
                disabled={sioDetails.connection !== "connected"}
              >
                <Settings2 className={classes.chatIcon} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown className={classes.dropdownData}>
              <Button
                variant="subtle"
                className={classes.menuPreferenceButton}
                onClick={() => {
                  dispatch(
                    updateReceivingChannel({
                      channel: messageData.channel,
                      enabled:
                        !sioDetails.receivingChannels[messageData.channel],
                    })
                  );
                }}
                leftIcon={
                  sioDetails.receivingChannels[messageData.channel] ? (
                    <VolumeOff />
                  ) : (
                    <Volume />
                  )
                }
              >
                {sioDetails.receivingChannels[messageData.channel]
                  ? "Mute channel"
                  : "Unmute channel"}
              </Button>
              <Button
                variant="subtle"
                className={classes.deleteMessageButton}
                onClick={() => {
                  dispatch(removeNthMessage(idx));
                }}
                leftIcon={<TrashX />}
              >
                {"Delete message"}
              </Button>
            </Menu.Dropdown>
          </Menu>
        </div>
        <div className={classes.message}>{messageData.message}</div>
      </div>
    );
  };

  const eventMessage = (messageData: MessageDataType, idx: number) => (
    <div key={crypto.randomUUID()} className={classes.eventMessage}>
      {messageData.message}
    </div>
  );

  return (
    <>
      <div className={classes.chatBox}>
        {sioDetails.messages.map((data, idx) => {
          if (data.type === "event") return eventMessage(data, idx);
          if (data.type === "sent") return sentMessage(data, idx);
          if (data.type === "received") return receivedMessage(data, idx);
        })}
      </div>
    </>
  );
}
