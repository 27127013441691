import { configureStore } from "@reduxjs/toolkit";
import httpDetailsReducer from "./HttpMethodSlice";
import routingInfoReducer from "./RoutingSlice";
import drawerInfoReducer from "./DrawerSlice";
import wsDetailsReducer from "./WebsocketSlice";
import sioDetailsReducer from "./SioSlice";
import envDetailsReducer from "./EnvSlice";
import collectionDetailsReducer from "./CollectionSlice";

const store = configureStore({
  reducer: {
    httpDetails: httpDetailsReducer,
    routingInfo: routingInfoReducer,
    drawerInfo: drawerInfoReducer,
    wsDetails: wsDetailsReducer,
    sioDetails: sioDetailsReducer,
    envDetails: envDetailsReducer,
    collectionDetails: collectionDetailsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AsyncThunkCustomType = { state: RootState;
  dispatch: any }

export default store;
