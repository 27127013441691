import { MessageType } from "../../data/_store/WebsocketSlice";
import { wsArray } from "../../data/connections";

export type WebSocketConnection = {
  sendMessage: (message: string) => void;
  closeWs: (id: string) => void;
};

export default async function connectToWebsocket(
  dispatch: (message: string, type: MessageType) => void,
  uri: string
) {
  //give an initial state so that the data won't be undefined at start
  return new Promise<string>((resolve, reject) => {
    const id = crypto.randomUUID();
    try {
      // const ws = new WebSocket("ws://localhost:3030");
      const ws = new WebSocket(uri);

      ws.onerror = (err) => {
        reject("The path specified was not reachable");
      };

      ws.onopen = (event) => {
        resolve(id);
      };

      ws.onmessage = function (event) {
        try {
          dispatch(event.data, "received");
        } catch (err) {
          console.log(err);
        }
      };

      ws.onclose = function (event) {
        try {
          dispatch("disconnected", "event");
        } catch (err) {
          console.log(err);
        }
      };

      const sendMessage = (message: string) => {
        try {
          ws.send(message);
          dispatch(message, "sent");
        } catch (err) {
          console.log(err);
        }
      };

      const closeWs = (id: string) => {
        try {
          ws.close();
        } catch (err) {
          console.log(err);
        }
      };

      wsArray.push({
        wsId: id,
        functions: { sendMessage, closeWs },
      });
    } catch (err) {
      return reject(err);
    }
  });
}
