import classes from "../Websocket.module.css";
import { Button } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../data/_store/store";
import {
  WebsocketDetails,
  setCrtMessage,
} from "../../../data/_store/WebsocketSlice";
import { wsArray } from "../../../data/connections";

export default function InputBox() {
  const wsDetails = useSelector<RootState, WebsocketDetails>(
    (state) => state.wsDetails
  );

  const dispatch = useDispatch<any>();

  return (
    <>
      <div className={classes.inputBox}>
        <div className={classes.inputBoxElement}>
          <textarea
            className={classes.textarea}
            value={wsDetails.crtMessage}
            disabled={wsDetails.connection !== "connected"}
            onChange={(ev) => {
              dispatch(setCrtMessage(ev.currentTarget.value));
            }}
          ></textarea>
        </div>
        <div className={classes.inputBoxElementCtrlButton}>
          <Button
            styles={(theme) => ({
              root: {
                backgroundColor: "#992200",
                "&:not([data-disabled])": theme.fn.hover({
                  backgroundColor: theme.fn.darken("#00acee", 0.05),
                }),
              },
            })}
            disabled={wsDetails.connection !== "connected"}
            onClick={() => {
              wsArray
                .find(({ wsId, functions }) => wsId === wsDetails.wsId)
                ?.functions.sendMessage(wsDetails.crtMessage);

              dispatch(setCrtMessage(""));
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
}
