import { createSlice } from "@reduxjs/toolkit";
import { Protocol } from "../_db/db";

export type PageType =
  | "new"
  | "env"
  | Protocol.HTTP
  | Protocol.SIO
  | Protocol.WS
  | undefined;
  
export type DrawerType =
  | "history"
  | "environments"
  | "saved"
  | "collections"
  | undefined;

export type ActiveSelectionType =
  | "New"
  | "Collections"
  | "Environments"
  | "Saved"
  | "History";

export interface RoutingInfoType {
  drawer: DrawerType;
  page: PageType;
  activeSelection: ActiveSelectionType;
}

const initialState: RoutingInfoType = {
  drawer: undefined,
  page: "new",
  activeSelection: "New",
};
export const routingInfoSlice = createSlice({
  name: "routingInfo",
  initialState: initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setActiveSelection: (state, action) => {
      state.activeSelection = action.payload;
    },

    restoreToDefault: (state) => {
      state = initialState;
    },
  },
});

export const { setDrawer, setPage, setActiveSelection } =
  routingInfoSlice.actions;

export default routingInfoSlice.reducer;
