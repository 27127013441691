import { ActionIcon, Text } from "@mantine/core";
import { Checks, TrashX } from "tabler-icons-react";
import { deleteEntryFromEnvById } from "../../../data/_db/repositoryEnv";
import classes from "../Drawers.module.css";
import { EnvEntry } from "../../../data/_db/db";
type EnvEntryElementPropsType = {
  entry: EnvEntry;
  filterMask: string;
  selected: string;
  getFilteredData: (filter: string) => Promise<void>;
  setSelected: (id: string) => any;
  loadRequest: (envEntry: EnvEntry) => void;
};

export default function EnvEntryElement({
  entry,
  getFilteredData,
  filterMask,
  selected,
  setSelected,
  loadRequest,
}: EnvEntryElementPropsType) {
  return (
    <div
      className={`${classes.inputGroup} ${
        selected === entry.id ? classes.selectedInputGroup : ""
      }`}
      key={`${entry.id}`}
    >
      {selected === entry.id && (
        <Checks className={`${classes.selectedIcon}`} />
      )}
      <Text
        className={`${classes.inputButton}`}
        onClick={() => {
          loadRequest(entry);
          setSelected(entry.id);
        }}
      >
        {entry.data.name}
      </Text>
      <ActionIcon
        color="red"
        className={classes.delete}
        onClick={() => {
          deleteEntryFromEnvById(entry.id);
          getFilteredData(filterMask);
        }}
      >
        <TrashX />
      </ActionIcon>
    </div>
  );
}
