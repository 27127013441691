import { Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "../Rest.module.css";
import KeyValueInput from "./KeyValueInput";

export type KeyValueType = {
  key: string;
  value: string;
};
export type KeyValueListProp = {
  saveToStore: (a: Record<string, string>) => any;
  initialValue: Record<string, string>;
};
const initHeaders = (iv: Record<string, string>) => {
  const elm: Record<string, KeyValueType> = Object.entries(iv).reduce(
    (acc, [key, value]) => {
      const ts = crypto.randomUUID();
      return { ...acc, [ts]: { key, value } };
    },
    {}
  );

  return elm == null ? {} : elm;
};

export default function HeadersTab({
  saveToStore,
  initialValue,
}: KeyValueListProp) {
  const [keyValueList, setKeyValueList] = useState<
    Record<string, KeyValueType>
  >(initHeaders(initialValue));
  const [keyValueErrorList, setKeyValueErrorList] = useState<
    Record<string, string>
  >({});

  const dispatch = useDispatch();

  useEffect(() => {
    const errEntries: Record<string, string> = {};

    const elm = Object.entries(keyValueList).reduce((acc, [ts, keyValue]) => {
      if (countDuplicateKey(keyValue.key) > 1)
        errEntries[ts] = "Duplicate: Last value will be used";
      return { ...acc, [keyValue.key]: keyValue.value };
    }, {});

    dispatch(saveToStore(elm));

    setKeyValueErrorList(errEntries);
  }, [keyValueList]);
  
  const countDuplicateKey = (key: string) => {
    return Object.entries(keyValueList).filter(
      ([elmTs, elm]) => elm.key.toLowerCase() === key.toLowerCase()
    ).length;
  };

  const add = (
    <Button
      variant="gradient"
      gradient={{ from: "orange", to: "red" }}
      onClick={() => {
        createInput();
      }}
    >
      ADD
    </Button>
  );

  const createInput = () => {
    const ts = new Date().getTime().toString();
    setKeyValueList((prevState) => ({
      ...prevState,
      [ts]: {
        key: "",
        value: "",
      },
    }));
  };

  const updateInput =
    (type: "key" | "value", ts: string) => (value: string) => {
      let prevKeyValueList = { ...keyValueList };

      prevKeyValueList[ts][type] = value;

      setKeyValueList(prevKeyValueList);
    };

  const deleteKeyValueList = (ts: string) => () => {
    const prevKeyValueList: Record<string, KeyValueType> = { ...keyValueList };

    delete prevKeyValueList[ts];

    setKeyValueList(prevKeyValueList);
  };

  const getKeyValueList = () => {
    return Object.entries(keyValueList)
      .sort((e1, e2) => parseInt(e1[0]) - parseInt(e2[0]))
      .map(([ts, keyValue]) => (
        <KeyValueInput
          kvKey={keyValue.key}
          kvValue={keyValue.value}
          key={ts + "KeyValueInput"}
          delete={deleteKeyValueList(ts)}
          onPersistKey={updateInput("key", ts)}
          onPersistValue={updateInput("value", ts)}
          duplicateKey={keyValueErrorList[ts]}
        />
      ));
  };

  return (
    <div
      className={classes.tabContentWrapper}
      key={"initialValue" + JSON.stringify(initialValue, null, 2)}
    >
      {add}
      <div className={classes.scrollableContent}>{getKeyValueList()}</div>
    </div>
  );
}
