import { Input, TextInput } from "@mantine/core";
import { debounce } from "lodash";
import classes from "../Rest.module.css";
import { useState } from "react";

export type ParamsType = {
  paramName: string;
  onPersistValue: (value: string) => void;
};
export default function PathParam(params: ParamsType) {
  const [value, setValue] = useState<string>("");

  const onDebouncedInputValueChange = debounce((value: string) => {
    setValue(value);
    params.onPersistValue(value);
  }, 300);

  const getPairElements = (
    <div className={classes.inputGroup}>
      <TextInput
        className={classes.inputArea}
        defaultValue={params.paramName}
        readOnly={true}
        // disabled={true}
      ></TextInput>
      <Input
        className={classes.inputArea}
        defaultValue={value}
        onChange={(event) =>
          onDebouncedInputValueChange(event.currentTarget.value)
        }
      ></Input>
    </div>
  );

  return getPairElements;
}
