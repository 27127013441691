// db.ts
import Dexie, { Table } from "dexie";
import { EnvItemType } from "../_store/EnvSlice";
import { HttpDetails } from "../_store/HttpMethodSlice";
import { PageType } from "../_store/RoutingSlice";
import { SioDetails } from "../_store/SioSlice";
import { WebsocketDetails } from "../_store/WebsocketSlice";

export enum Protocol {
  HTTP = "rest",
  WS = "websocket",
  SIO = "sio",
}

export enum CollectionType {
  DIRECTORY = "directory",
  ENTRY = "entry",
}

export type ConnectionData =
  | { protocol: Protocol.HTTP; data: HttpDetails }
  | { protocol: Protocol.WS; data: WebsocketDetails }
  | { protocol: Protocol.SIO; data: SioDetails };

export type CollectionNode = EntryCollectionNode | DirectoryCollectionNode;

export type EntryCollectionNode = {
  date: Date;
  id: string;
  name: string;
  parent: string | null;
  type: CollectionType.ENTRY;
  entryData: ConnectionData;
};
export type DirectoryCollectionNode = {
  date: Date;
  id: string;
  name: string;
  parent: string | null;
  type: CollectionType.DIRECTORY;
};

export type ArchiveEntry = {
  id: string;
  date: Date;
} & ConnectionData;

export type EnvEntry = {
  id: string;
  type: PageType;
  data: EnvEntryData;
  date: Date;
};
export type EnvType = EnvEntry[];

export type EnvData = {
  name: string | undefined;
  kvList: Record<string, EnvItemType>;
};

export type LoadInfo = {
  crtEnv: EnvEntry | undefined;
};
export type EnvEntryData = EnvData;

export class CarouselDexie extends Dexie {
  history!: Table<ArchiveEntry>;
  saved!: Table<ArchiveEntry>;
  env!: Table<EnvEntry>;
  collections!: Table<CollectionNode>;

  constructor() {
    super("carousel");
    this.version(1).stores({
      history: "id,protocol,data,date", // Primary key and indexed props
      saved: "id,protocol,data,date", // Primary key and indexed props
      env: "id,type,data,date", // Primary key and indexed props
      collections: "id,type,entryData,date,name,parent", // Primary key and indexed props
    });
  }
}

export const db = new CarouselDexie();
