import { ActionIcon, Text } from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TrashX } from "tabler-icons-react";
import { ArchiveEntry, ConnectionData, Protocol } from "../../data/_db/db";
import {
  deleteEntryFromSavedById,
  nrOfEntriesInSavedLQ,
} from "../../data/_db/repositorySaved";
import { getMoreSavedData } from "../../data/_store/DrawerSlice";
import { loadSavedInfo as loadSavedInfoRest } from "../../data/_store/HttpMethodSlice";
import { setPage } from "../../data/_store/RoutingSlice";
import { loadSavedInfo as loadSavedInfoSio } from "../../data/_store/SioSlice";
import { RootState } from "../../data/_store/store";
import { loadSavedInfo as loadSavedInfoWS } from "../../data/_store/WebsocketSlice";
import { launchEvent } from "../../helpers/events";
import classes from "./Drawers.module.css";
import InfiniteScroll from "./InfiniteScroll";

const nrOfElementsOnDisplay = 22;
const batchNumber = 10;

export const computeLabelForRestrictedData = ({
  protocol,
  data,
}: ConnectionData): string => {
  switch (protocol) {
    case Protocol.HTTP:
      return `${data.httpMethod.toUpperCase()} ${data.uri}`;
    case Protocol.WS:
      return `WEBSOCKET ${data.uri} `;
    case Protocol.SIO:
      return `SIO ${data.uri} `;
  }
  return "";
};

export const computeLabel = (entryData: ConnectionData | null): string => {
  switch (entryData) {
    case null:
      return "";
    default:
      return computeLabelForRestrictedData(entryData);
  }
};
const loadRequest = (dispatch: any, savedEntry: ArchiveEntry) => {
  switch (savedEntry.protocol) {
    case "rest":
      dispatch(setPage(savedEntry.protocol));
      dispatch(loadSavedInfoRest(savedEntry.data));
      break;

    case "websocket":
      dispatch(setPage(savedEntry.protocol));
      dispatch(loadSavedInfoWS(savedEntry.data));
      break;

    case "sio":
      dispatch(setPage(savedEntry.protocol));
      dispatch(loadSavedInfoSio(savedEntry.data));
      break;
  }
};

const builderFunction = (entry: ArchiveEntry, dispatch: any) => {
  return (
    <div className={classes.inputGroup} key={`${entry.id}`}>
      <Text
        className={`${classes.inputButton}`}
        onClick={() => {
          loadRequest(dispatch, entry);
        }}
      >
        {computeLabel({
          protocol: entry.protocol,
          data: entry.data,
        } as ConnectionData)}
      </Text>
      <ActionIcon
        color="red"
        className={classes.delete}
        onClick={() => {
          deleteEntryFromSavedById(entry.id);
          //reloadPage
        }}
      >
        <TrashX />
      </ActionIcon>
    </div>
  );
};

export default function Saved() {
  const savedData = useSelector<RootState, ArchiveEntry[]>(
    (state) => state.drawerInfo.savedData
  );

  const [filterMask, setFilterMask] = useState("");
  const dispatch = useDispatch<any>();

  const nrOfElementsInDB: number = useLiveQuery(nrOfEntriesInSavedLQ) ?? -1;
  const [prevNrOfElementsInDB, setPrevNrOfElementsInDB] = useState(-1);

  useEffect(() => {
    if (prevNrOfElementsInDB != -1) {
      launchEvent("onDbUpdate", {});
    }
    setPrevNrOfElementsInDB(nrOfElementsInDB);
  }, [nrOfElementsInDB]);
  const onBoundaryCall = (
    offset: number,
    numberOfElements: number,
    next: boolean,
    replace: boolean
  ) => {
    dispatch(
      getMoreSavedData({
        offset,
        numberOfElements,
        next,
        replace,
      })
    );
  };
  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          overflow: "scroll",
          height: "85vh",
        }}
      >
        {nrOfElementsInDB > 0 && (
          <InfiniteScroll
            nrOfElementsOnDisplay={nrOfElementsOnDisplay}
            batchNumber={batchNumber}
            nrOfElementsInDB={nrOfElementsInDB}
            elementHeight={
              +getComputedStyle(document.body).getPropertyValue(
                "--height-input-group"
              )
            }
            onBoundaryCall={onBoundaryCall}
            data={savedData}
            builderFunction={(entry: ArchiveEntry) => {
              return builderFunction(entry, dispatch);
            }}
          />
        )}
      </div>
    </>
  );
}
