import classes from "./Drawers.module.css";
import { X as Close } from "tabler-icons-react";
import { ActionIcon, Text } from "@mantine/core";
import { setDrawer } from "../../data/_store/RoutingSlice";
import { useDispatch } from "react-redux";

export type DrawerProp = { children: JSX.Element; name: string };
export default function Drawer({ children, name }: DrawerProp) {
  const dispatch = useDispatch<any>();
  return (
    <>
      <div className={classes.drawer}>
        <div className={classes.headerDrawer}>
          <Text
            className={`${classes.drawerTitle}  ${classes.spaceGroteskFont}`}
          >
            {name}
          </Text>
          <ActionIcon
            variant="transparent"
            onClick={() => dispatch(setDrawer(undefined))}
          >
            <Close size="1rem" className={classes.closeDrawer} />
          </ActionIcon>
        </div>
        <div className={`${classes.drawerContentWrapper}`}>{children}</div>
      </div>
    </>
  );
}
