import { LoadingOverlay } from "@mantine/core";
import { useSelector } from "react-redux";
import { RootState } from "../../data/_store/store";

import classes from "../SocketIO/Sio.module.css";
import SioHeader from "./SioHeader/SioHeader";
import Tab from "./TabGroup/Tab";
import { SioDetails } from "../../data/_store/SioSlice";

export default function Sio() {
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );

  return (
    <>
      <LoadingOverlay
        loaderProps={{ size: "xl", color: "blue", variant: "bars" }}
        overlayOpacity={0.6}
        overlayColor="#c5c5c5"
        visible={sioDetails.connection === "loading"}
      />

      <div className={classes.sio}>
        <div className={classes.sioContentWrapper}>
          <SioHeader />
          <div className={classes.panelWrapper}>
            <Tab />
          </div>
        </div>
      </div>
    </>
  );
}
