import { useSelector } from "react-redux";
import { RootState } from "../../../data/_store/store";
import { WebsocketDetails } from "../../../data/_store/WebsocketSlice";
import classes from "../Websocket.module.css";

export default function Chat() {
  const sentMessage = (message: string) => {
    return (
      <div key={crypto.randomUUID()} className={classes.sentMessage}>
        {message}
      </div>
    );
  };

  const receivedMessage = (message: string) => {
    return (
      <div key={crypto.randomUUID()} className={classes.receivedMessage}>
        {message}
      </div>
    );
  };

  const eventMessage = (message: string) => (
    <div key={crypto.randomUUID()} className={classes.eventMessage}>
      {message}
    </div>
  );
  const wsDetails = useSelector<RootState, WebsocketDetails>(
    (state) => state.wsDetails
  );

  return (
    <>
      <div className={classes.chatBox}>
        {wsDetails.messages.map((data) => {
          if (data.type === "event") return eventMessage(data.message);
          if (data.type === "sent") return sentMessage(data.message);
          if (data.type === "received") return receivedMessage(data.message);
          return null;
        })}
      </div>
    </>
  );
}
