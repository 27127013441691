import { Card, Text } from "@mantine/core";
import { useDispatch } from "react-redux";
import { setPage } from "../../data/_store/RoutingSlice";
import classes from "./New.module.css";

export default function New() {
  const dispatch = useDispatch<any>();

  return (
    <>
      <div className={classes.grid}>
        <Card
          shadow="sm"
          className={classes.cardClass}
          padding="xl"
          component="a"
          onClick={() => {
            dispatch(setPage("rest"));
          }}
          target="_blank"
        >
          <Text className={classes.workbenchCardText}>REST</Text>
        </Card>
        <Card
          shadow="sm"
          className={classes.cardClass}
          padding="xl"
          component="a"
          onClick={() => {
            dispatch(setPage("websocket"));
          }}
          target="_blank"
        >
          <Text className={classes.workbenchCardText}>WEBSOCKET</Text>
        </Card>
        <Card
          shadow="sm"
          className={classes.cardClass}
          padding="xl"
          component="a"
          onClick={() => {
            dispatch(setPage("sio"));
          }}
          target="_blank"
        >
          <Text className={classes.workbenchCardText}>SOCKET IO</Text>
        </Card>
      </div>
    </>
  );
}
