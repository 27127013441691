import classes from "../../Sio.module.css";
import { Button, Select } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../data/_store/store";
import {
  SioDetails,
  addEmittingChannels,
  setCrtEmittingChannel,
  setCrtMessage,
} from "../../../../data/_store/SioSlice";
import { sioArray } from "../../../../data/connections";
import { PhoneOutgoing } from "tabler-icons-react";

export default function InputBox() {
  const sioDetails = useSelector<RootState, SioDetails>(
    (state) => state.sioDetails
  );

  const dispatch = useDispatch<any>();
  return (
    <>
      <div className={classes.inputArea}>
        <div className={classes.inputAreaWrapper}>
          <div className={classes.inputControlArea}>
            <Select
              className={classes.emittingChannelSelector}
              disabled={sioDetails.connection !== "connected"}
              creatable
              searchable
              clearable
              value={sioDetails.crtEmittingChannel}
              icon={<PhoneOutgoing />}
              placeholder="Select emitting channel"
              onChange={(event) => {
                dispatch(setCrtEmittingChannel(event));
              }}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(value) => {
                if (
                  !sioDetails.emittingChannels.find(
                    (channel) => channel === value
                  )
                )
                  dispatch(addEmittingChannels(value));
                return value;
              }}
              data={sioDetails.emittingChannels}
            />
            <Button
              className={classes.sendMessageButton}
              styles={(theme) => ({
                root: {
                  backgroundColor: "#992200",
                  "&:not([data-disabled])": theme.fn.hover({
                    backgroundColor: theme.fn.darken("#00acee", 0.05),
                  }),
                },
              })}
              disabled={sioDetails.connection !== "connected"}
              onClick={() => {
                sioArray
                  .find(({ sioId, functions }) => sioId === sioDetails.sioId)
                  ?.functions.sendMessage(
                    sioDetails.crtMessage,
                    sioDetails.crtEmittingChannel
                  );

                dispatch(setCrtMessage(""));
              }}
            >
              Send
            </Button>
          </div>

          <textarea
            className={classes.messageArea}
            value={sioDetails.crtMessage}
            disabled={sioDetails.connection !== "connected"}
            onChange={(ev) => {
              dispatch(setCrtMessage(ev.currentTarget.value));
            }}
          ></textarea>
        </div>
      </div>
    </>
  );
}
